.text-field-wrapper{
    height: 5px;
    /* margin-bottom: 30px; */
    margin-top: -40px;
    background-color:white;
    z-index: 100;
}

.start-btn-wrapper{
    height: 5px;
    margin-top: -40px;
    z-index: 100;
}

.text-field{
    background-color:white;
}

.send-btn-section{
    background-color: var(--primary-theme-color);
    padding: 10px;
    height: 100%;
    align-items: center;
    justify-content:center;
    margin-right: -14px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    align-content: center;
    cursor: pointer;
}


.send-arrow-icon{
    width: 25px;
    height: 25px;
}

.send-btn-section-disabled{
    background-color: var(--tertiary-gray-color);
    padding: 10px;
    height: 100%;
    align-items: center;
    justify-content:center;
    margin-right: -14px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    align-content: center;
}

.chat-main-display-content{
    background-color: var(--white-color);
    border-radius: 10px;
    box-shadow: 0 2px 60px 0 rgba(0.06, 0, 0, 0.06);
}


@media only screen and (max-width: 768px) {
    .send-arrow-icon{
        margin-top:5px;
        width: 20px;
        height: 20px;
    }
    .send-btn-section{
        height: 46px;
    }
    .text-field-wrapper, 
    .start-btn-wrapper{
        position: fixed;
        bottom: 0;
        width: 90%;
        margin-left: -20px;
        height: 20px;
        margin-bottom: 30px;
    }
}