.new-launch-item {
  padding: 25px 30px;
  border-radius: 20px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white-color);
}

.new-launch-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
}

.name-location-holder {
  display: flex;
  align-items: center;
}

.name-location-holder > img {
  object-fit: cover;
  height: 80px;
  width: 80px;
  border-radius: 10px;
  margin-right: 10px;
}

.name-location-holder > div > h2 {
  font-family: "Jost-SemiBold";
  font-size: 28px;
  line-height: 1.32;
  color: rgba(0, 0, 0, 0.72);
  margin-bottom: 8px;
}

.name-location-holder > div > span {
  font-family: "Jost-Regular";
  font-size: 20px;
  line-height: 1.7;
  color: rgba(0, 0, 0, 0.72);
}

.name-location-holder > div > h2 > span {
  font-family: "Jost-Bold";
  font-size: 26px;
  color: #21b342;
}

.favorite-icon {
  margin-right: 30px;
}

.amount-area-holder {
  padding: 10px 30px;
  border-radius: 10px;
  background-color: var(--primary-gray-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.amount-area-holder div {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}

.amount-area-holder p,
.amount-area-holder span {
  font-size: 28px;
  text-align: right;
  color: var(--quaternary-black-color);
}

.amount-area-holder p {
  font-family: "Jost-Medium";
}

.amount-area-holder span {
  font-family: "Jost-Light";
}

.carousel-holder {
  margin-top: 5px;
}

.carousel-holder img {
  border-radius: 8px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.details-holder {
  margin-top: 15px;
  padding: 20px;
  border-radius: 12px;
}

.full-desc-text {
  font-family: "Jost-Light";
  font-size: 1.5rem;
  color: var(--quaternary-black-color);
  padding-right: 20px;
}

.view-details-btn-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rera-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  margin-right: auto;
  margin-left: auto;
}

.rera-numbers {
  font-family: "Jost-Light";
  font-size: 18px;
  color: #313131;
  text-align: center;
}

.info-icon {
  height: 30px;
  cursor: pointer;
}

.apply-now-btn-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.apply-now-btn-holder button {
  padding-right: 80px;
  padding-left: 80px;
}

.left-icon {
  margin-right: 10px;
}

.rera-number-holder {
  display: flex;
  flex-direction: row;
}

.upward-icon {
  height: 20px;
  margin-left: 15px;
}

@media only screen and (max-width: 768px) {
  .new-launch-item {
    padding: 5px 10px;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white-color);
  }

  .new-launch-holder {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .rera-number-holder {
    display: block;
  }

  .name-location-holder > img {
    object-fit: cover;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    margin-right: 10px;
  }

  .name-location-holder > div > span {
    font-size: 14px;
    line-height: 1;
  }

  .name-location-holder > div > h2 {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 0px;
  }

  .name-location-holder > div > h2 > span {
    font-family: "Jost-Bold";
    font-size: 14px;
    color: #21b342;
  }

  .favorite-icon {
    margin-right: 10px;
  }

  .upward-icon {
    height: 10px;
    margin-left: 5px;
  }

  .full-desc-text {
    font-size: 1rem;
    padding-right: 10px;
  }

  .amount-area-holder {
    margin-top: 5px;
    padding: 10px 10px;
    width: 100%;
  }

  .amount-area-holder div {
    flex-direction: row;
  }

  .amount-area-holder p,
  .amount-area-holder span {
    font-size: 16px;
    text-align: right;
    color: var(--quaternary-black-color);
  }

  .amount-area-holder p {
    font-family: "Jost-Medium";
    margin-right: 5px;
  }

  .amount-area-holder span {
    font-family: "Jost-Light";
  }

  .carousel-holder {
    margin-top: 0px;
  }

  .details-holder {
    padding: 10px;
  }

  .apply-now-btn-holder {
    margin-top: 10px;
  }

  .apply-now-btn-holder button {
    width: 100%;
  }

  .rera-numbers {
    font-family: "Jost-Light";
    font-size: 1rem;
    color: #313131;
    text-align: center;
    margin-right: 50px;
  }

  .info-icon {
    height: 15px;
    cursor: pointer;
  }

  .left-icon {
    margin-right: 0;
  }
}
