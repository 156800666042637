.submit-btn-holder {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Registration Success Dialog CSS styles starts here */
.registration-heading-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.registration-heading-holder img {
  height: 50px;
  width: auto;
  object-fit: contain;
}

.registration-heading-holder h2 {
  font-family: "Jost-Medium";
  font-size: 1.625rem;
  line-height: 2.01;
  text-align: center;
  margin-right: 20px;
  margin-left: 20px;
  color: var(--quaternary-black-color);
}

.registration-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: linear-gradient(103deg, #faf4d1 6%, #f4e8a9 92%);
  border-top-left-radius: 0px;
  border-top-right-radius: 90px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  padding: 60px 80px;
  margin-right: 40px;
  margin-left: 40px;
}

.registration-banner img {
  height: 80px;
  width: auto;
  object-fit: contain;
  margin-right: 50px;
}

.registration-banner p {
  font-family: "Jost-Italic";
  font-size: 24px;
  font-style: italic;
  color: #393000;
}

@media only screen and (max-width: 768px) {
  .submit-btn-holder {
    margin-top: 80px;
  }

  .registration-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 0px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px 20px;
    margin-right: 0;
    margin-left: 0;
  }

  .registration-heading-holder img {
    height: 30px;
  }

  .registration-heading-holder h2 {
    font-size: 1.125rem;
  }

  .registration-banner img {
    height: 40px;
    margin-right: 30px;
  }

  .registration-banner p {
    font-size: 14px;
  }
}
