.new-launch-item {
  padding: 25px 30px;
  border-radius: 20px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white-color);
}

.section-heading-holder {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.section-heading-holder > div > h1 {
  font: 500 1.875rem "Jost-Medium";
}

.section-heading-holder > div > span {
  font: 500 1.5rem "Jost-Regular";
}

.new-launch-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name-location-holder {
  display: flex;
  align-items: center;
}

.name-location-holder > img {
  object-fit: cover;
  height: 80px;
  width: 80px;
  border-radius: 10px;
  margin-right: 10px;
}

.name-location-holder > div > h2 {
  font-family: "Jost-SemiBold";
  font-size: 28px;
  line-height: 1.32;
  color: rgba(0, 0, 0, 0.72);
  margin-bottom: 8px;
}

.name-location-holder > div > span {
  font-family: "Jost-Regular";
  font-size: 20px;
  line-height: 1.7;
  color: rgba(0, 0, 0, 0.72);
}

.name-location-holder > div > h2 > span {
  font-family: "Jost-Bold";
  font-size: 26px;
  color: #21b342;
}

.upward-icon {
  height: 20px;
  margin-left: 15px;
  margin-right: 3px;
}

.amount-area-holder {
  padding: 10px 30px;
  border-radius: 10px;
  background-color: var(--primary-gray-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
}

.amount-area-holder div {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}

.amount-area-holder p,
.amount-area-holder span {
  font-size: 26px;
  text-align: right;
  color: var(--quaternary-black-color);
}

.amount-area-holder p {
  font-family: "Jost-Medium";
}

.amount-area-holder span {
  font-family: "Jost-Light";
}

.carousel-holder {
  margin-top: 40px;
}

.carousel-holder img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details-holder {
  margin-top: 15px;
  background-color: var(--primary-gray-color);
  padding: 20px;
  border-radius: 12px;
}

.details-holder p {
  font-family: "Jost-Light";
  font-size: 1.5rem;
  color: var(--quaternary-black-color);
  padding-right: 20px;
}

.view-detail-icon {
  display: none;
}

.view-details-btn-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-details-btn,
.view-details-btn:hover {
  border-radius: 6px;
  border: 1px solid var(--black-color);
  background-color: var(--white-color);
  color: var(--black-color);
  font-family: "Jost-Medium";
}

.views-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff2b2;
  width: 60%;
  border-radius: 10px;
  padding-left: 40px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
}

.apply-btn-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.views-holder > p {
  font-family: "Jost-Regular";
  font-size: 1.75rem;
  color: #66570d;
}

.views-holder > p > span {
  font-family: "Jost-SemiBold";
}

.heading-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.heading-holder h2 {
  font-family: "Jost-Regular";
  font-size: 1.5rem;
  line-height: 1.6;
  color: #16161c;
}

@media only screen and (max-width: 768px) {
  .section-heading-holder > div > h1 {
    font: 500 1rem "Jost-Medium";
  }

  .section-heading-holder > div > span {
    font: 500 0.875rem "Jost-Regular";
  }

  .heading-holder h2 {
    font-size: 1.2rem;
  }

  .new-launch-item {
    padding: 5px 10px;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white-color);
  }

  .new-launch-holder {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }

  .name-location-holder > img {
    object-fit: cover;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    margin-right: 10px;
  }

  .name-location-holder > div > span {
    font-size: 14px;
    line-height: 1;
  }

  .name-location-holder > div > h2 {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 0px;
  }

  .name-location-holder > div > h2 > span {
    font-family: "Jost-Bold";
    font-size: 14px;
    color: #21b342;
  }

  .upward-icon {
    height: 10px;
    margin-left: 5px;
  }

  .amount-area-holder {
    margin-top: 10px;
    padding: 10px 10px;
    width: 100%;
  }

  .amount-area-holder div {
    flex-direction: row;
  }

  .amount-area-holder p,
  .amount-area-holder span {
    font-size: 16px;
    text-align: right;
    color: var(--quaternary-black-color);
  }

  .amount-area-holder p {
    font-family: "Jost-Medium";
    margin-right: 5px;
  }

  .amount-area-holder span {
    font-family: "Jost-Light";
  }

  .carousel-holder {
    margin-top: 5px;
  }

  .details-holder {
    padding: 10px;
  }

  .details-holder p {
    font-size: 1rem;
    padding-right: 10px;
  }

  .view-details-btn {
    display: none;
  }

  .view-detail-icon {
    display: block;
  }

  .views-holder {
    width: 100%;
    padding-left: 10px;
    margin-top: 10px;
  }

  .views-holder > p {
    font-size: 1rem;
    padding-right: 10px;
  }

  .views-holder button {
    width: 40%;
    padding: 0;
  }

  .apply-btn-holder {
    margin-top: 10px;
  }

  .apply-btn-holder button {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .amount-area-holder {
    padding: 3px;
  }

  .amount-area-holder p,
  .amount-area-holder span {
    font-size: 1.25rem;
  }
}
