.section-heading {
  font-family: "Jost-Medium";
  font-size: 1.875rem;
  color: var(--quaternary-black-color);
  margin-bottom: 20px;
  margin-top: 10px;
  text-align: left;
}

.project-amenity-card {
  background-color: var(--white-color);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
}

.project-amenity-card img {
  height: 40px;
  width: 30px;
  object-fit: contain;
  margin-right: 15px;
}

.project-amenity-card span {
  font-family: "Jost-Regular";
  font-size: 1.375rem;
  color: var(--quaternary-black-color);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.heading-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.collapse-icon {
  transform: rotate(180deg);
}

@media only screen and (max-width: 768px) {
  .project-amenity-card {
    padding: 10px;
  }

  .project-amenity-card img {
    height: 30px;
    width: 25px;
    margin-right: 10px;
  }

  .project-amenity-card span {
    font-size: 1rem;
  }

  .section-heading {
    font-size: 1rem;
    margin-bottom: 20px;
    margin-top: 12px;
  }

  .heading-holder {
    margin-bottom: 5px;
  }
}
