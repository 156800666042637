.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.header img {
  height: 38px;
  width: auto;
  object-fit: contain;
  margin-right: 10px;
}

.chat-notification>h3 {
  width: 112px;
  height: 41px;
  margin: 50px 100px 39px 2px;
  font-family: "Jost-Regular";
  font-size: 1.5rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.chat-container>p {
  font-family: "Jost-Regular";
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.375rem;
  color: #494a67;
}

.header-update {
  font-size: 1.125rem;
  line-height: 0.89;
  letter-spacing: normal;
  font-family: "Jost-Regular";
  color: #707070;
}

.header-date {
  font-size: 1.125rem;
  line-height: 0.67;
  letter-spacing: normal;
  color: #141615;
  font-family: "Jost-Medium";
}

.chat-refresh-image {
  margin-left: 20px;
}

.chat-refresh-image:hover {
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
}

.date-and-time {
  display: flex;
  align-items: center;
  font-size: 1rem;
  text-align: center;
  font-family: "Jost-Regular";
}

.chatcard-icon {
  width: 81px;
  height: 83px;
  border-radius: 6px;
}

.chat-card-detail>h2 {
  font-family: "Jost-Medium";
  font-size: 1.55rem;
  /* line-height: 1.25; */
  color: #17171c;
}

.chat-list-holder {
  height: 670px;
  overflow-y: scroll;
  padding-left: 12px;
  background-color: var(--white-color);
  margin-bottom: 20px;
  padding: 16px;
}

.chat-searchBox input {
  position: relative;
  width: 380px;
  height: 44px;
  margin: 0 47px 14px;
  padding: 11px 314px 10px 15px;
  border-radius: 2.9px;
  border: solid 0.7px #9192a0;
  background-color: #fff;
}

.chat-searchBox {
  padding-left: 100px;
  padding-top: 12px;
}

.chat-box-header {
  display: flex;
  align-items: center;
  /* height: 10%; */
  border-bottom: solid 1.4px #eee;
  text-align: start;
  justify-content: flex-start;
  padding-top: 20px;
  background-color: #fff;
}

.chatBox-header-logo {
  display: flex;
  padding-left: 50px;
  justify-content: flex-start;
  text-align: start;
  align-items: center;
  column-gap: 3px;
}

.chatBox-header-logo img {
  width: 45px;
  height: 45px;
  margin: 0 18px 0 0;
  /* padding: 14px 5px; */
  border-radius: 3.2px;
}

.chatBox-header-logo>p {
  font-family: "Jost-Regular";
  font-size: 1.5625rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #17171c;
}

.chat-box {
  /* height: calc(100vh - 350px); */
  height: 530px;
  border-radius: 14px;
  overflow-y: scroll;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--white-color);
}

.chatcard-container {
  display: flex;
  justify-content: flex-start;
  background-color: var(--white-color);
}

.chat-item {
  height: 100%;
  background-color: var(--white-color);
}

.chat-message {
  width: 386px;
  border-radius: 7.8px;
  background-color: #f0f1ff;
}

.chat-message>div {
  font-family: "Jost-Regular";
  color: #494a67;
  padding: 13px 16px 20px 13px;
  font-size: 1rem;
}

.menu-items-search-holder {
  margin-left: 300px;
  padding-right: 30px;
}

.chat-bubble {
  background-color: #f0f1ff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  /* margin-left: 10px; */
  min-height: 50px;
  width: 400px;
  font-family: "Jost-Regular";
  font-size: 1rem;
}

.chat-box-textField {
  align-content: center;
  position: fixed;
}

.chatbody {
  /* margin-top: 40px; */
  padding: 25px 30px;
  min-height: 600px;
}

.chat-contact-item {
  padding: 12px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 5px;
  cursor: pointer;
}

.chat-contact-item>img {
  height: 80px;
  width: 80px;
  border-radius: 6px;
  object-fit: cover;
}

.chat-content-holder {
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 10px;
  width: 100px;
}

.chat-content-holder div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.chat-content-holder h3 {
  margin-bottom: 12px;
  margin-top: 2px;
  font-family: "Jost-Regular";
  font-size: 1.125rem;
  line-height: 1.25;
  color: #17171c;
}

.chat-content-holder p {
  font-family: "Jost-Light";
  font-size: 1rem;
  white-space: nowrap;
  width: 268px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #17171c;
}

.chat-content-holder span {
  font-family: "Jost-Medium";
  font-size: 1rem;
  line-height: 1.36;
  text-align: right;
  color: #9192a0;
}

.chat-date-time {
  font-family: "Jost-Regular";
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.chat-date-time p {
  margin-left: 340px;
  padding-bottom: 25px;
}

.chat-date-time span {
  margin-left: 50px;
}

.start-chat-btn>Button {
  width: 45%;
  height: 45px;
  border-radius: 6px;
  background-color: #000;
  margin-bottom: 50px;
  gap: 15px;
  font-family: "Jost-Medium";
  font-size: 1.2rem;
  text-align: center;
  justify-content: center;
}

.start-chat-btn {
  justify-content: center;
  margin-top: -50px;
  text-align: center;
}

.chat-query {
  padding: 15px;
  margin-left: 10px;
  margin-top: 10px;
  margin-top: 10px;
  width: 282px;
  height: 49px;
  border-radius: 5.2px;
  background-color: #fff;
  font-family: "Jost-Medium";
  font-size: 1rem;
  color: #494a67;
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.1);
  border-style: none;
  display: flex;
}

.line-ellipsis {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.updated-at-section {
  justify-content: flex-end;
  text-align: end;
}

.updated-at-section>p {
  color: red;
  color: #9192a0;
  font-family: "Jost-Medium";
}

@media only screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .header-date {
    font-size: 0.8rem;
  }

  .header-update {
    font-size: 0.8rem;
  }

  .chat-card-detail>h2 {
    font-size: 1rem;
  }

  .chat-container>p {
    font-size: 0.9rem;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: start;
  }

  .chat-list-holder {
    margin: 0px -10px 0px 0px;
    padding: 3px;
  }

  .chat-box-header {
    position: fixed;
    background-color: var(--white-color);
    z-index: 100;
    width: 100%;
    top: 0;
    padding-top: 10px;
  }

  .chat-content-holder h3 {
    font-size: 1rem;
  }

  .chat-contact-item>img {
    height: 60px;
    width: 60px;
  }

  .chat-content-holder p {
    font-size: 0.8rem;
  }

  .chat-box {
    margin: 0px -40px 0 -40px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh);
  }

  .chat-bubble {
    padding: 15px;
    margin-bottom: 10px;
    min-height: 50px;
    width: 220px;
    font-family: "Jost-Regular";
    font-size: 0.8rem;
  }

  .chat-query {
    padding: 10px;
    margin-top: 8px;
    width: 200px;
    height: 40px;
    font-size: 0.8rem;
  }

  .start-chat-btn>Button {
    font-size: 0.9rem;
    width: 90%;
  }

  .start-chat-btn {
    margin-top: -30px;
  }

  .chatBox-header-logo>p {
    font-size: 1.2rem;
  }

  .chatbody {
    /* padding: 10px 15px; */
    padding: 60px 0px 5px 15px;
    min-height: 600px;
  }

  .updated-time-text {
    font-size: 0.7rem;
  }

  .chat-content-holder {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 0px;
    width: 100px;
  }
}

@media only screen and (max-width: 350px) {
  .header-date {
    font-size: 0.7rem;
  }

  .header-update {
    font-size: 0.7rem;
  }
}