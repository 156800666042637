.chat-us-holder {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.want-to-connect {
  font-family: "Jost-Regular";
  font-size: 1.125rem;
  text-align: center;
  color: var(--quaternary-black-color);
  margin-bottom: 10px;
}

.or-text {
  color: var(--senary-gray-color);
  text-align: center;
  font-family: "Jost-Regular";
  font-size: 1.125rem;
  margin-top: 10px;
}

.chat-btn {
  padding-right: 30px;
  padding-left: 30px;
}

.ph-num-email-holder {
  display: flex;
  align-items: center;
}

.ph-num-email-holder span {
  color: var(--senary-gray-color);
  font-family: "Jost-Regular";
  font-size: 1.25rem;
  margin-right: 10px;
}

.phone-num {
  color: #292936;
  font-family: "Jost-SemiBold";
  font-size: 1.5rem;
  margin-right: 10px;
}

.email {
  font-family: "Jost-Medium";
  font-size: 1.2rem;
  margin-right: 10px;
  color: var(--primary-theme-color);
  text-decoration: underline;
  cursor: pointer;
}

.justify-right-items{
  justify-content: flex-start;
}

.justify-left-items {
  justify-content: end;
}

.typography-gray{
  color: gray;
}

.typography-section-heading{
  font-size: 1.3rem;
  font-family: 'Jost-semiBold'
}

.left-arrow{
  cursor: pointer;
  color: black
}

.chat-image{
  width: 20px;
  height: 20px;
}

@media only screen and (max-width: 900px){
  .justify-left-items,
  .justify-right-items{
    justify-content: center;
  }

  .email{
    font-size: 1rem;
  }
  .typography-gray{
    font-size: 0.9rem;
  }
  .or-text{
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
  .chat-btn{
    font-size: 0.9rem;
  }
  .typography-section-heading{
    font-size: 1.1rem;
    color: var(--primary-theme-color);
  }
  .chat-image{
    width: 13px;
    height: 13px;
  }
}
