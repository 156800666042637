.account-title-text{
    font-size: 1.3rem;
    font-weight: 500;
    margin-left: 4px;
}

.content-padding{
    padding : 19px;
}

.content-padding-status{
    padding : 2px 15px 0px 15px;
}

.card-content{
    margin-top: 15px;
    box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.06);
    border-radius: 10px;
}

.view-btn{
    text-decoration: underline;
    color: var(--primary-theme-color);
    font-size: 1rem;
}

.see-all-btn{
    color: var(--primary-theme-color);
    font-size: 1.1rem;
    text-transform: uppercase;
}

.status-card-padding{
    padding: 10px 20px 10px 20px;
}

.status-text{
    color : #bcbcbc;
}

.status-value{
    color : #ffab0b;
    margin-left: 5px;
}

.divider{
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-color: #e8eaff;
}

.status-section-content{
    display: flex;
}

.typography-content-name{
    font-size : 1.05rem;
}


@media only screen and (max-width:700px){
    .account-title-text{
        font-size: 1rem;
    }

    .content-padding{
        padding : 12px;
    }

    .card-content{
        margin-top: 10px;
    }

    .typography-content-name{
        font-size: 0.85rem;
    }

    .view-btn,
    .see-all-btn,
    .status-value{
        font-size: 0.8rem;
    }
    .status-section-content{
        flex-direction: column;
    }
    .divider {
        margin-left: -8px;
        margin-right: -8px;
    }
    .status-text{
        font-size: 0.9rem;
    }
    .status-value{
        margin-left: 0px;
    }
    .content-padding-status{
        padding : 15px;
    }
}