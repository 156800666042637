.left-split {
  padding: 1rem 0px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  word-break: break-word;
}

.left-split p {
  margin: 20px 0;
  font-family: "Jost-Light";
  font-size: 1.25rem;
  color: var(--black-color);
}
.left-split h1 {
  font-size: 1.875rem;
  font-family: "Jost-Medium";
}
.see-all-promise {
  text-transform: none;
}
@media (min-width: 460px) and (max-width: 890px) {
  .left-split {
    flex-direction: row;
    font-size: 16px;
  }
}
@media (max-width: 459px) {
  .left-split {
    flex-direction: row;
    padding: 0;
  }
  .left-split h1 {
    font-size: 1rem;
  }
  .see-all-promise {
    min-width: 78px;
  }
}
