.page-footer-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-footer {
  padding: 10px;
  border-radius: 6px;
  background-color: #fff2b2;
  width: 60%;
  margin-bottom: 30px;
  margin-top: 30px;
  font-family: "Jost-Regular";
  font-size: 1.5rem;
  text-align: center;
  color: #66570d;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 768px) {
  .page-footer {
    width: 100%;
    font-size: 1.125rem;
    margin-bottom: 5px;
  }
}
