.card-dimension {
  width: 100%;
  padding: 40px 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 20px;
  background-image: linear-gradient(100deg, #505064 4%, #343445 94%);
  cursor: pointer;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.card-dimension h1 {
  font: normal 2.3rem "Jost-Medium";
  color: var(--white-color);
  margin-bottom: 20px;
}

.card-dimension div {
  flex-basis: 95%;
}

.card-dimension p {
  font: normal 1.675rem "Jost-Regular";
  color: #d3d3d9;
  width: 80%;
}

@media only screen and (max-width: 768px) {
  .card-dimension {
    padding: 20px;
    margin-bottom: 50px;
    border-radius: 10px;
  }

  .card-dimension h1 {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }

  .card-dimension p {
    font-size: 1rem;
  }
}
