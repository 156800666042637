.download-heading {
  font-family: "Jost-Bold";
  font-size: 2.25rem;
  text-align: center;
  color: var(--black-color);
  margin-bottom: 20px;
}

.stores-icon-holder {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.playstore-icon {
  width: 170px;
  height: auto;
  object-fit: contain;
  margin-right: 5px;
  cursor: pointer;
}
.mobile-icon {
  width: 90%;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .stores-icon-holder > div {
    display: flex;
    align-items: center;
  }

  .download-heading {
    font-size: 1.5rem;
  }

  .playstore-icon {
    width: 110px;
  }
}
