.sku-card {
  padding: 15px 0px 15px 0px;
  border-radius: 10px;
  box-shadow: 0 11px 28px 0 rgba(0, 0, 0, 0.06);
  height: 100%;
  min-height: 50px;
}

.sold-out-card {
  background-color: #8b8b8b;
}

.apply-card {
  background-color: var(--white-color);
}

.sold-out-starting-at-text,
.sold-out-name-text,
.sold-out-area-text,
.sold-out-amount,
.sold-out-description {
  color: var(--white-color);
}

.apply-now-starting-at-text {
  color: #636474;
}

.apply-now-name-text {
  color: var(--black-color);
}

.sku-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.apply-now-area-text {
  color: var(--senary-gray-color);
}

.sku-content > span {
  font-family: "Jost-Regular";
  font-size: 1.5rem;
  text-align: center;
  padding-top: 5px;
}

.sku-card h2 {
  font-family: "Jost-Medium";
  font-size: 1.5rem;
  text-align: center;
  /* color: var(--black-color); */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.starting-at {
  font-family: "Jost-Regular";
  font-size: 1.25rem;
  margin-right: 5px;
}

.amount {
  font-family: "Jost-SemiBold";
  font-size: 1.375rem;
}

.apply-now-amount {
  color: #636474;
}

.apply-now-btn {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  color: var(--quaternary-black-color);
}

.applied-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 11px;
}

.applied-btn img {
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin-right: 10px;
}

.applied-btn > span {
  font-family: "Jost-Medium";
  font-size: 1.25rem;
  text-align: right;
  color: #9d9eb1;
}

.sold-out-btn-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-holder > div {
  display: flex;
  align-items: center;
}

.btn-holder img {
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin-right: 10px;
}

.btn-holder span {
  font-family: "Jost-Medium";
  font-size: 1.25rem;
  text-align: right;
  color: #9d9eb1;
}

@media only screen and (max-width: 768px) {
  .sku-card {
    padding: 10px;
  }

  .sku-content > span {
    font-size: 1rem;
    padding-top: 0px;
  }

  .sku-card h2 {
    font-size: 1rem;
  }

  .starting-at {
    font-size: 0.875rem;
  }

  .amount {
    font-size: 1rem;
  }

  .applied-btn {
    padding: 0px;
    margin-top: 20px;
  }

  .applied-btn > span {
    font-size: 1rem;
  }
}
