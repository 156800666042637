.okay-btn {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--primary-theme-color)
}

.pending-payment-dialog-header {
    padding: 10px 10px
}

.dialog-content-section {
    text-align: center;
    align-items: center;
}

.pending-amount-mention-section {
    background-image: linear-gradient(101deg, #faf4d1 5%, #f4e8a9 93%);
    margin: 0px 30px;
    padding: 20px 0px;
    border-radius: 10px;
}


.dashed-divider {
    border-bottom: 0.7px dashed #e3e2f0;
    margin-top: 15px;
}

.dashed-card-divider {
    border-bottom: 0.7px dashed #e3e2f0;
    margin: 15px -20px;
}

.copy-text {
    font-size: 1.2rem;
    color: #676ac0;
    text-decoration: underline;
    cursor: pointer;
}

.typography-heding {
    font-size: 1.3rem;
    font-family: "Jost-Medium";
}

.clear-icon {
    font-size: 1.7rem;
}

.typography-title-bold {
    font-size: 1.7rem;
    font-family: "Jost-Medium";
}

.typography-title {
    font-size: 1.3rem;
    font-family: "Jost-Medium";
}

.typography-title-2 {
    font-size: 1.2rem;
    font-family: "Jost-Medium";
}

.typography-text {
    font-size: 1.1rem;
    font-family: "Jost-Regular";
}

.text-dark-yellow {
    color: #61592b;
}

.line-ellipsis {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dialog-actions {
    text-align: center;
}

.typography-heading {
    font-size: 1.45rem;
    font-family: "Jost-semiBold"
}

.light-gray-color {
    color: #7b7878
}

@media only screen and (max-width: 768px) {
    .typography-heading {
        font-size: 1.1rem;
    }

    .clear-icon {
        font-size: 1.3rem;
    }

    .typography-title-bold {
        font-size: 1.35rem;
    }

    .typography-title {
        font-size: 1rem;
    }

    .typography-text {
        font-size: 0.9rem;
    }

    .typography-title-2 {
        font-size: 1rem;
    }

    .okay-btn {
        font-size: 1rem;
        padding: 10px 0;

    }

    .dashed-card-divider {
        margin: 10px -20px;
    }

    .dashed-divider {
        margin-top: 0;
    }

    .pending-amount-mention-section {
        margin: 0px;
        padding: 20px 0px;
    }

    .dialog-content-section {
        margin-top: -20px
    }
}