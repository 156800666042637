
.typography-theme-text{
    color : var(--primary-theme-color);
    font-size : 1.3rem;
    font-family: 'Jost-Medium'
}

.typography-text-bold{
    font-size : 1.1225rem;
    color : var(--black-color);
    font-family: 'Jost-Medium'
}

.payment-card{
    padding : 10px 20px 10px 20px;
}


.see-receipt-btn{
    color: var(--primary-theme-color);
    font-size: 1.1rem;
    text-decoration: underline;
}

.typography-text-italic{
    font-style: italic;
    font-size: 1.2rem;
}


@media only screen and (max-width:700px){
    .typography-theme-text{
        font-size : 1rem;
    }
    .typography-text-bold{
        font-size : 0.8rem;
    }
    .payment-card{
        padding : 10px 20px 10px 20px;
    }
    .content-sm-flex{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .see-receipt-btn{
        font-size : 0.8rem;
    }
    .typography-text-italic{
        font-size: 0.9rem;
    }
}

