.main-container-for-card {
  display: flex;
  /* justify-content: center; */
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: left;
  flex-direction: column;
  overflow-x: hidden;
}
.content-div-in-security-tips {
  width: 95%;
}

.main-container-for-card-holder {
  height: 100%;
  width: 100%;
  /* background-color: aquamarine; */
  display: flex;
  justify-content: center;
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.1);
}

.description-text {
  font-size: 1.1125rem;
  font-family: "Jost-Regular";
}

.securitytips-heading {
  font-size: 1.5rem;
  font-family: "Jost-semiBold";
}

.close-img {
  width: 25px;
  height: 25px;
  margin-top: 15px;
}

.heading-div {
  margin-bottom: 28px;
  display: flex;
  justify-content: space-between;
}

.img-div {
  height: auto;
  width: 100%;
}
.imgg {
  width: 100%;
  height: 350px;
  object-fit: contain;
}
.main-text-content {
  width: auto;
}

.main-span {
  margin-top: 20px;
}

.sub-discription {
  margin-left: 50px;
  margin-top: 20px;
}

.left-arrow {
  cursor: pointer;
}

.display-name-heading {
  font-size: 1.2rem;
  font-family: "Jost-Medium";
}

.line-ellipsis {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 768px) {
  .description-text {
    font-size: 1rem;
  }
  .securitytips-heading {
    font-size: 1.2rem;
    color: var(--primary-theme-color);
  }
  .display-name-heading {
    font-size: 1.005rem;
  }
  .imgg {
    width: 100%;
    height: 200px;
  }
}
