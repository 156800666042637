.faq-card {
  margin: 0 0 24px;
  padding: 20px 24px;
  border-radius: 14.4px;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.faq-card p {
  width: 90%;
  font-family: "Jost-Light";
  font-size: 1.5rem;
  color: #3f416d;
}

.btn-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.btn-holder button {
  padding-right: 100px;
  padding-left: 100px;
}

@media only screen and (max-width: 768px) {
  .faq-card p {
    font-size: 1rem;
  }

  .btn-holder button {
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
  }
}
