.collection-item {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.06);
  }
  
  .collection-item-content {
    position: relative;
  }
  
  .title-subtitle-detail-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .title-subtitle-holder,
  .title-subtitle-holder-soldout {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .text-background {
    height: 50%;
    background-color: #f4f4f6;
    position: absolute;
    width: 100%;
    z-index: -1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .collection-image-holder {
    padding-left: 10px;
  }
  
  .collection-image {
    object-fit: contain;
    height: 150px;
    width: 220px;
    border-radius: 10px;
  }

  .image-container{
    position: relative;
    text-align: center;
    color: white;
    border-radius: 10px;
    height : fit-content;
  }

  .image-titles{
    position: absolute;
    bottom: 8px;
    width: 100%;
    background-color: rgb(45, 43, 43);
    color: white;
    opacity: 0.7;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: -1.108px;
    padding: 2px 0px;
  }

  .image-titles{
    position: absolute;
    bottom: 8px;
    width: 100%;
    background-color: rgb(45, 43, 43);
    color: white;
    opacity: 0.7;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: -1.108px;
    padding: 2px 0px;
  }

 .typography-white{
    color : white;
    font-size: 0.95rem;
    text-transform: ellipsis;
    font-family: 'Jost-semiBold';
 }
  
  .title-subtitle-holder > h2 {
    font-family: "Jost-semiBold";
    font-size: 1.2rem;
    line-height: 1.08;
    color: var(--quaternary-black-color);
  }

  .title-subtitle-holder-soldout > h2 {
    font-family: "Jost-semiBold";
    font-size: 1.2rem;
    line-height: 1.08;
    color: var(--white-color);
  }
  
  .title-subtitle-holder > span {
    font-family: "Jost-Regular";
    font-size: 1rem;
    line-height: 1.44;
    color: #918fa3;
  }

  .title-subtitle-holder-soldout{
    font-family: "Jost-Regular";
    font-size: 1rem;
    line-height: 1.44;
    color: var(--white-color)
  }
  
  .percentage {
    font-family: "Jost-Bold";
    font-size: 1.25rem;
    /* color: #44b75d; */
  }
  
  .description {
    font-family: "Jost-Light";
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.4;
    color: var(--quaternary-black-color);
    margin-top: 10px;
    background-color: var(--tertiary-gray-color);
  }

  .apply-now-btn{
    color: black;
    font-size: 1.2rem;
    font-family: "Jost-Regular";
  }

  .collection-card-image{
    width: 100%;
    background-color: var(--tertiary-gray-color);
    border-radius: 10px;
  }

  .description{
    padding : 10px;
    min-height: 80px;
    text-align: center;
  }

  .margin-left{
    margin-left: 10px;
  }

  .card-image-container{
    position: relative;
    color: rgb(132, 127, 127);
    border-bottom-left-radius: 8px;
    height: fit-content;
    max-width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    cursor: pointer;
  }

  .card-image-container-soldout{
    position: relative;
    color: rgb(132, 127, 127);
    /* border-bottom-left-radius: 8px; */
    height: fit-content;
    max-width: 100%;
    /* border-top-right-radius: 10px; */
    /* border-top-left-radius: 10px; */
  }

  .card-media-mui{
    width: 400px;
    max-width: 270px;
    border-radius: 10px;
    height : 130px;
  }

  .media-opacity{
    opacity: 0.4
  }

  .description-text{
    font-size: 1rem;
    font-family: "Jost-Regular";
  }

  .description-text-soldout{
    color: --var(white-color);
  }

  .card-main-container{
    padding: 10px 20px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.11);
    border-radius: 10px;
  }

  .card-details-content-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
  }

  .description-content-wrapper{
    display: flex;
    text-align: start;
    padding: 5px;
    background-color: #f4f4f6;
    border-end-end-radius: 10px;
    min-height: 40px;
  }

  .description-content-wrapper-soldout{
    display: flex;
    text-align: start;
    padding: 5px;
    border-end-end-radius: 10px;
    min-height: 40px;
  }

  .description-text-ellipsis-2{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Jost-Regular";
    font-size: 1.1rem;
}

.line-ellipsis{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description-content-wrapper{
  min-height: 55px
}

.card-easticon{
  cursor: pointer;
}

.sold-out-text-wrapper{
  background-color: var(--white-color);
  padding: 0px 5px;
  margin: 0 -3px 0 0;
}

.sold-out-text{
  font-family: "Jost-Regular";
  font-size: 1.1275rem;
  margin-top: 3px;
}

  @media only screen and (max-width: 700px){
    .title-subtitle-holder > h2,
    .title-subtitle-holder-soldout > h2{
        font-size: 1rem;
    }
    .title-subtitle-holder > span,
    .title-subtitle-holder-soldout > span{
        font-size: 0.8rem
    }
    .card-easticon{
        font-size: 1.2rem
    }
    .description{
        font-size: 0.8rem;
    }
    .apply-now-btn{
        font-size: 0.9rem
    }
    .collection-image{
        height: 200px;
        width: 240px;
    }
    .percentage{
        font-size: 1rem
    }
    .typography-white{
        font-size: 0.8rem;
        text-overflow: ellipsis;
    }
    .description-text,
    .description-text-soldout{
      font-size: 0.8rem;
    }
    .card-media-mui{
      border-radius: 10px;
      height : 120px;
      max-width: 200px;
    }
    .card-main-container{
      padding: 5px 8px
    }
    .sold-out-text{
      font-size: 0.925rem;
    }
  }

  @media only screen and (max-width: 400px){
    .card-media-mui{
      height : 105px;
      max-width: 125px;
    }
    .typography-white{
      font-size: 0.7rem;
  }
  }

  @media only screen and (max-width: 320px){
    .card-media-mui{
      height : 105px;
      max-width: 115px;
    }
    .typography-white{
      font-size: 0.65rem;
  }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1350px){
    .card-media-mui{
      max-width: 225px;
    }
  }
  