.section-padding{
    padding: 30px 30px 10px 30px;
}

.timeline-circle {
    width: 4px;
    height: 4px;
    margin-top: 3px;
    background-color: #32ba7c;
    border-radius: 50%;
  }

  .timeline-circle-disabled {
    width: 5px;
    height: 5px;
    margin-top: 3px;
    margin-left: -0.5px;
    background-color:  #cfcece;
    border-radius: 50%;
  }

.typography-main-heading{
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.timeline-content-text {
    text-align: top;
    align-items: top;
}

.typography-heading{
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
}

.typography-text{
  font-size: 1rem;
}

.typography-text-disabled{
  font-size: 1rem;
  color: #707193
}

.typography-view{
  color: var(--primary-theme-color);
  font-size: 1.1rem;
  text-decoration: underline;
}

.status-gap-space{
  width: 400px; 
}

.status-details-container{
  /* width: 200%; */
  border: 0.2px solid #a1a3da;
}

.status-details {
  padding: 1px 10px;
  /* width: 100%; */
  background-color: #ebecf9;
}

.status-details-text{
  padding: 1px 5px;
  width: 90%;
  background-color: #ebecf9;
}

.line-ellipsis{
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-number-value{
  font-size: 1rem;
}

.completed-icon-lg,
.success-circle-lg {
  width: 30px;
  height: 28.5px;
  margin-left: -1.5px;
}

.completed-icon-sm,
.success-circle-sm{
  width: 23px;
  height: 20px;
}



@media only screen and (max-width: 768px) {
  .status-details-container{
    width: 250px;
  }
  .typography-heading{
    font-size: 0.9rem;
  }
  .typography-view{
    font-size: 0.6rem;
    margin-right: -15px
  }
  .typography-text-disabled,
  .typography-text,
  .status-details,
  .status-number-value,
  .typography-text-status {
    font-size: 0.7rem
  }
  .status-gap-space{
    width: 10px;
  }

  .completed-icon-lg,
  .success-circle-lg {
    width: 25px;
    height: 23.5px;
    margin-left: 1px;
  }

  .completed-icon-sm,
  .success-circle-sm{
    width: 18px;
    height: 15px;
    margin-left: 1.6px
  }
  .download-app-btn{
    font-size: 0.7rem;
  }
  
}

@media only screen and (max-width: 1200px) {
  /* .status-details-container{
    width: 450px;
  } */
  .status-gap-space{
    width: 250px; 
  }
}