.testimonials-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-slide-track {
  justify-content: center;
}

.testimonial-card {
  width: 100%;
  min-height: 200px;
  padding: 30px 75px;
  border-radius: 24px;
  /* box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.06); */
  background-color: var(--white-color);
  /* width: 80%; */
}

.testimonial-card>h2 {
  color: var(--senary-theme-color);
  font-family: "Jost-Regular";
  font-size: 26px;
  text-align: center;
}

.testimonial-card>div {
  font-family: "Jost-Regular";
  font-size: 24px;
  text-align: center;
  color: var(--black-color);
}

.testimonial-card>p {
  font: 300 26px/1.74 "Jost-Light";
  text-align: center;
  color: var(--black-color);
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .testimonial-card {
    padding: 10px;
  }

  .testimonial-card h2 {
    font-size: 1rem;
  }

  .testimonial-card p,
  .testimonial-card div {
    font-size: 0.8rem;
  }
}