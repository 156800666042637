.promise-holder {
  margin-top: 30px;
}

.heading-holder h2 {
  font-family: "Jost-Medium";
  font-size: 1.875rem;
  color: var(--quaternary-black-color);
  margin-top: 30px;
  margin-bottom: 25px;
  text-align: center;
}

.heading-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .promise-holder {
    margin-top: 20px;
  }

  .heading-holder h2 {
    font-size: 1rem;
    margin-top: 0px;
    margin-bottom: 5px;
    text-align: left;
  }
}
