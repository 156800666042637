.expected-growth-holder {
  margin-top: 50px;
}

.expected-growth-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.expected-growth-holder h2 {
  font-family: "Jost-Medium";
  font-size: 1.675rem;
  color: var(--quaternary-black-color);
  margin-bottom: 15px;
}

.expected-growth-holder p {
  font-family: "Jost-Regular";
  font-size: 1.3rem;
  color: #292833;
}
