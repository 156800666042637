.user-chat-card{
    background-color: var(--primary-theme-color);
    margin: 10px 20px;
    min-width: 150px;
    text-align: start;
    padding: 10px;
    border-radius: 5px;
    color: var(--white-color);
    font-size: 1rem;
    font-family : "Jost-Regular";
}

.check-icon-container{
    align-items: end;
    margin: 18px -9px -15px 2px;
}

.check-icon{
    font-size: 16px;
}

.user-chat-message{
    color: white;
    font-size: 1rem;
}

@media only screen and (max-width: 768px) {
    .user-chat-card{
        margin: 10px 20px;
        min-width: 120px;
        font-size: 0.9rem;
        padding: 7px 10px;
    }
    .user-chat-message{
        font-size: 0.8rem;
    }
    .check-icon-container{
        align-items: end;
        margin: 18px -8px -10px 2px;
    }
}