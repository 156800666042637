.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - var(--header-height));
  border-radius: 25px;
  background-color: var(--white-color);
}

.loader {
  margin: 0px auto 15px;
  width: 50px;
  height: 60px;
  text-align: center;
}
