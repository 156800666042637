.section-heading {
  font-family: "Jost-Medium";
  font-size: 1.875rem;
  color: var(--quaternary-black-color);
  margin-bottom: 5px;
  margin-top: 60px;
  text-align: center;
}

.current-infra-card {
  width: 100%;
  height: 350px;
  padding: 5px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06);
  background-color: var(--white-color);
  border-radius: 10px;
}

.image-title-holder {
  position: relative;
}

.image-title-holder div {
  padding: 8px 15px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.36);
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  border-radius: 0 0 10px 10px;
}

.image-title-holder div p {
  font-family: "Jost-Regular";
  font-size: 1.25rem;
  line-height: 1.03;
  color: var(--white-color);
  border-radius: 0 0 10px 10px;
}

.current-infra-card img {
  border-radius: 10px;
  object-fit: cover;
  width: 100%;
  height: 240px;
}

.current-infra-card > p {
  padding-top: 8px;
  font-size: 1rem;
  font-family: "Jost-Light";
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
}

@media only screen and (max-width: 768px) {
  .current-infra-card {
    height: 300px;
  }

  .current-infra-card img {
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
    height: 200px;
  }

  .section-heading {
    font-size: 1rem;
    margin-bottom: 0px;
    margin-top: 20px;
    text-align: left;
  }

  .image-title-holder div p {
    font-size: 1rem;
  }
}
