
  .image-container{
    position: relative;
    text-align: center;
    color: white;
    border-radius: 10px;
    height : fit-content;
  }
  .image-titles{
    position: absolute;
    bottom: 8px;
    width: 100%;
    color: white;
    opacity: 0.7;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: -1.3px;
    padding: 2px 0px;
  }

 .typography-white{
    color : white;
    font-size: 0.95rem;
    text-transform: ellipsis;
    font-family: 'Jost-Regular';
 }
  
  .card-image-container{
    position: relative;
    color: rgb(132, 127, 127);
    border-bottom-left-radius: 8px;
    height: fit-content;
    max-width: 200px;
  }

  .card-media-mui{
    width: 100%;
    position: relative;
    border-radius: 10px;
    height : 270px;
  }

  .card-media-mui-thumbnail{
    width: 100%;
    position: relative;
    border-radius: 10px;
    height : 400px;
  }

  

.line-ellipsis{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.container {
    position: relative;
    text-align: center;
    color: white;
  }
  
  .card-title {
    position: absolute;
    bottom: 8px;
    width: 100%;
    background-color: rgb(48, 44, 44);
    color: white;
    opacity: 0.75;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: -8px;
    padding: 2px 10px;
    text-align: start
  }

  .card-video-icon-content{
    position: absolute;
    bottom: 30px;
    left: 10px;
    /* width: 100%; */
    color: white;
    opacity: 0.7;
  }

  .card-video-icon-content-videoPlay{
    position: absolute;
    bottom: 40%;
    left: 40%;
    color: white;
  }

  .video-play-icon {
    color: white;
  }

  .video-play-icon-lg{
    width: 70px;
    height: 70px;
  }

  @media only screen and (max-width: 768px){
    .typography-white{
        font-size: 0.7rem;
        text-overflow: ellipsis;
    }
    .card-media-mui{
      width: 100%;
      border-radius: 10px;
      height : 200px;
    }
    .card-media-mui-thumbnail{
      width: 100%;
      position: relative;
      border-radius: 10px;
      height : 200px;
    }
    .video-play-icon-lg{
      width: 40px;
      height: 40px;
    }
  }

@media only screen and (max-width : 500px){
  .card-media-mui{
    width: 100%;
    border-radius: 10px;
    height : 130px;
  }
}


  