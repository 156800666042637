.typography-semiBold-theme {
    font-size: 1.2rem;
    font-family: "Jost-semiBold";
    color: var(--primary-theme-color);
}

.typography-medium {
    font-size: 1.2rem;
    font-family: "Jost-Medium";
    /* color: var(--primary-theme-color); */
}

/* .payment-card{
    align-items: center;
} */

.typograpghy-text,
.view-btn {
    font-size: 1.1225rem;
    font-family: "Jost-Regular";
}

.all-reciepts-disclaimer {
    margin: 40px 0 20px 0;
    text-align: center;
    padding: 0 20px;
}

.all-reciepts-disclaimer-text {
    color: var(--senary-gray-color);
    font-size: 1.2rem;
}

.typography-text-italic {
    font-style: italic;
    font-size: 1.2rem;
}

@media only screen and (max-width: 768px) {
    .typography-semiBold-theme {
        font-size: 1rem;
    }

    .typography-medium {
        font-size: 1rem;
    }

    .typograpghy-text,
    .view-btn {
        font-size: 0.95rem;
    }

    .all-reciepts-disclaimer {
        margin: 30px 0 20px 0;
        padding: 0 10px
    }

    .all-reciepts-disclaimer-text {
        font-size: 1rem;
    }

    .typography-text-italic {
        font-size: 0.9rem;
    }
}