.key-pillars-holder {
  box-shadow: 0 9px 24px 0 rgba(0, 0, 0, 0.03);
  border-radius: 30px;
  padding: 20px 10px 20px 10px;
  margin-top: 50px;
}

.key-pillars-holder h2 {
  font-family: "Jost-Medium";
  font-size: 1.875rem;
  color: var(--quaternary-black-color);
  margin-top: 30px;
  margin-bottom: 25px;
  text-align: center;
}

.key-pillar-card {
  padding: 30px 15px;
  border-radius: 16px;
  box-shadow: 0 10px 26px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white-color);
  height: 100%;
  min-height: 100px;
}

.heading-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.heading-holder h3 {
  font-family: "Jost-Medium";
  font-size: 1.5rem;
  color: #494a67;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.key-pillar-card img {
  height: 40px;
  width: 40px;
  object-fit: contain;
  margin-right: 15px;
}

.key-pillar-card p {
  font-family: "Jost-Regular";
  font-size: 1.25rem;
  text-align: center;
  color: var(--primary-theme-color);
  margin-top: 30px;
  padding: 0px 15px;
}

@media only screen and (max-width: 768px) {
  .key-pillars-holder {
    box-shadow: none;
    border-radius: 0px;
    padding: 10px;
    margin-top: 20px;
  }

  .key-pillars-holder h2 {
    font-size: 1rem;
    margin-top: 0px;
    margin-bottom: 5px;
    text-align: left;
  }

  .key-pillar-card {
    padding: 15px 10px;
    border-radius: 10px;
  }

  .heading-holder {
    margin-bottom: 15px;
  }

  .heading-holder h3 {
    font-size: 1rem;
    color: #494a67;
  }

  .key-pillar-card img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  .key-pillar-card p {
    font-size: 1rem;
    margin-top: 10px;
    padding: 0px;
  }
}
