.description-content{
    height : 330px;
    text-align: center;
    align-items: center;
    padding: 10px;
    align-content: center;
    text-overflow: hidden;
}

.mui-card-description-details-section{
    margin: -15px -15px 0px -15px;
    text-align: center;
}

.typography-card-title{
    font-size: 1.3rem;
    font-family: "Jost-semiBold";
}

.typography-card-sub-title{
    font-size: 1.2rem;
    font-family: "Jost-Light";
}

.description-text{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis !important;
    font-family: "Jost-Regular";
    font-size: 1.1rem;
}

.description-text-ellipsis-4{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Jost-Regular";
    font-size: 1.1rem;
}

.line-ellipsis{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media only screen and (max-width: 768px){
    .description-text {
        text-overflow: ellipsis;
        font-size: 0.9rem;
        -webkit-line-clamp: 5;
    }

    .description-content{
        height : 320px;
        text-align: center;
        align-items: center;
        padding: 2px;
        align-content: center;
        overflow: hidden;
    }

    .description-text-ellipsis-4{
        -webkit-line-clamp: 11;
        font-size: 0.9rem;
    }

    .typography-card-title{
        font-size:1.1rem;
    }

    .typography-card-sub-title{
        font-size:0.9rem;
    }
}