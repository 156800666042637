.download-app-section {
  padding: 30px 0px 50px 0px;
}

.heading-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-heading {
  font-family: "Jost-Bold";
  font-size: 2.25rem;
  text-align: center;
  color: var(--black-color);
  margin-bottom: 20px;
}

.stores-icon-holder {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.playstore-icon {
  width: 170px;
  height: auto;
  object-fit: contain;
  margin-right: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .download-heading {
    font-size: 1.5rem;
  }

  .playstore-icon {
    width: 140px;
  }
}
