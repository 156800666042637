.insights-section {
  padding: 30px 0px 50px 0px;
}
.insights-section .slide-container {
  gap: 1rem;
}

.insights-card {
  width: 100%;
  /* height: 150px; */
  cursor: pointer;
  background-color: var(--white-color);
  border-radius: 10px;
  box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.insights-heading {
  font-family: "Jost-Medium";
  font-size: 1.625rem;
  color: var(--black-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.insights-card div {
  width: 45%;
  height: 170px;
  border-radius: 10px;
}
.insights-card div img {
  object-fit: cover;
}
.insights-card .insights-content-holder {
  width: 55%;
  padding: 0.5rem 0.8rem;
  word-break: break-word;
}

.insights-card .insights-content-holder p {
  font: 300 1.125rem "Jost-Light";
  color: #313131;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

@media only screen and (max-width: 600px) {
  .insights-section {
    padding: 0;
  }
  .insights-card div {
    width: 100%;
    height: 138;
  }
  .insights-card {
    height: auto;
    flex-direction: column;
  }
  .insights-card .insights-content-holder {
    width: 100%;
  }
  .insights-card .insights-content-holder h2 {
    font-size: 1rem;
  }
  .insights-card .insights-content-holder p {
    font-size: 1rem;
  }
}
