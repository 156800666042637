.menu-container {
    padding: 20px 35px 20px 35px;
    border: 1px solid var(--quaternary-light-gray-color);
}

.menu-container-no-border {
    padding: 20px 35px 20px 35px;
    border: 1px solid var(--quaternary-light-gray-color);
    border-top: none;
}

.menu-item-title {
    font-family: "Jost-Medium";
    /* font-size: 1.1125rem; */
    color: var(--quaternary-black-color);
}

.menu-item-sub-title {
    /* font-family: "Jost-Regular";
    font-size: 1rem; */
    color: var(--senary-gray-color);
}

@media only screen and (max-width:600px) {
    .menu-container {
        padding: 8px 12px 8px 12px;
    }

    .menu-container-no-border {
        padding: 8px 12px 8px 12px;
    }

    /* .menu-item-title {
        font-size: 0.93rem;
    } */

    /* .menu-item-sub-title {
        font-size: 0.83rem;
    } */

    .east-icon {
        font-size: 1.25rem;
    }

}