.section-container {
    padding: 30px 50px 20px 40px;
}

.typography-main-heading {
    font-size: 1.3rem;
    font-weight: bold;
}

.typography-main-heading-2 {
    font-size: 1.2rem;
    font-weight: bold;
}

.timeline-details-section{
    box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.06);
    padding: 10px;
    min-height: 100px;
    align-items: center;
    border-radius: 10px;
}

.typography-text-1{
    font-size: 1.2rem;
    font-family: "Jost-Regular";
}

.typography-text-2{
    font-size: 1.1rem;
    font-family: "Jost-Regular";
}

@media only screen and (max-width:768px){
    .section-container {
        padding: 20px 0px 0px 0px;
        margin-left: -20px;
    }

    .typography-main-heading{
        font-size: 1rem;
    }
    .typography-main-heading-2{
        font-size: 0.85rem;
    }
    .typography-text-1{
        font-size: 1rem;
    }
    .typography-text-2{
        font-size: 0.85rem;
    }
}