.promotions-holder {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}

.image-banner {
  width: 50%;
  height: auto;
  cursor: pointer;
}

.heading-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.heading-holder h2 {
  font-family: "Jost-Regular";
  font-size: 24px;
  line-height: 1.6;
  color: #16161c;
}

@media only screen and (max-width: 768px) {
  .image-banner {
    width: 100%;
  }
}
