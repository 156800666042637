.white-color {
    color: var(--white-color);
    font-size: 1.2rem;
}

.dialog-tilte {
    margin-bottom: 25px;
}

.dialog-image,
.dialog-video {
    width: 850px;
    height: 400px;
}

.dialog-content {
    padding: 0;
}

.slide-content-wrapper {
    padding: 0 60px 0 72px;
}

@media only screen and (max-width:850px) {
    .dialog-image {
        width: 100%;
        height: 250px;
    }

    .dialog-video {
        width: 100%;
        height: 250px;
        margin: 10px 0 20px 0;
    }

    .white-color {
        font-size: 1rem;
    }

    .background-transparent {
        background-color: rgb(181, 179, 179);
        padding-top: 70px;
    }

    .slide-content-wrapper {
        padding: 0;
        margin-right: -15px
    }

    .slide-content-video {
        padding: 0;
        margin-right: -10px
    }

}