.tab-content{
    font-size: 1rem;
    text-transform: capitalize;
}

.content-wrapper{
    /* box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.07); */
    padding: 20px 10px;
}

.please-wait-content{
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 40vh;
}

.please-wait-icon{
    width: 200px;
    height: 170px;
}

.please-wait-text{
    font-size: 1rem;
    color: var(--quaternary-gray-color)
}

@media only screen and (max-width: 768px){
    .tab-content{
        font-size: 0.7rem;
    }
    .content-wrapper{
        padding: 20px 0px;
    }
    .please-wait-icon{
        width: 120px;
        height: 100px;
    }
    .please-wait-text{
        font-size: 0.8rem;
    }
    .please-wait-content{
        width: 500px
    }
}

@media only screen and (max-width: 1200px) and (min-width: 768px){
    .please-wait-icon{
        width: 150px;
        height: 120px;
    }
}