.search-field {
    background-color: var(--white-color);
    width : 100%;
}

.section-heading-name {
    font-size: 1.4rem;
    font-family: 'Jost-semiBold';
}

.categories-section {
    margin-top : 20px;
}

.category-heading {
    margin-left : 20px;
    font-size: 1.2rem;
    font-family: 'Jost-semiBold';
}

.carousel-content{
    margin-top : 20px;
}

.carousel-item{
    background-image: linear-gradient(109deg, #faf4d1 6%, #f4e8a9 92%);
    font-size: 1rem;
    min-width : 200px;
    font-family: "Jost-semiBold"

}

.carousel-box{
    cursor: pointer;
}

.accordion-details-text{
    font-family: "Jost-Regular";
}

.category-details-item {
    margin-top : 30px;
}

.accordion-summary-text{
    color : #3f416d;
    font-family: 'Jost-Regular';
    font-size: 1.2rem;
}

.left-arrow{
    cursor: pointer;
}


@media only screen and (max-width:600px){
    .faq-section{
        padding-top:20px;
    }
    .search-field{
        margin-top : 10px;
    }

    .section-heading-name{
        font-size: 1.1rem;
        color: var(--primary-theme-color)
    }

    .category-heading {
        font-size: 1rem;
    }

    .carousel-item{
        font-size: 0.9rem;
        min-width : 150px;
        justify-content:center;
        text-align: center;
        align-items: center;
        color: #8b7506;
    }

    .accordion-details-text{
        font-size: 0.9rem;
    }
    .accordion-summary-text{
        font-size: 0.9rem;
    }
}