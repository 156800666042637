:root {
  --header-height: 90px;

  --white-color: #ffffff;
  --secondary-white-color: #fbfbff;
  --tertiary-white-color: #f6f6fa;
  --quaternary-white-color: #f7f6ff;

  --black-color: #000000;
  --secondary-black-color: #111111;
  --tertiary-black-color: #2d2d2d;
  --quaternary-black-color: #17171c;
  --quinary-black-color: #141615;

  --primary-theme-color: #676ac0;
  --secondary-theme-color: #53569b;
  --tertiary-theme-color: #242e4c;
  --quaternary-theme-color: #5348d0;
  --quinary-theme-color: #4a39ff;
  --senary-theme-color: #272165;

  --primary-blue-magenta-color: #8682bb;
  --secondary-blue-magenta-color: #c0bcef;
  --tertiary-blue-magenta-color: #c9c4ff;
  --quaternary-blue-magenta-color: #bab3ff;
  --quinary-blue-magenta-color: #686680;
  --senary-blue-magenta-color: #9795af;

  --primary-gray-color: #f7f7f7;
  --secondary-gray-color: #f8f8f8;
  --tertiary-gray-color: #eaeaf1;
  --quaternary-gray-color: #afaeb7;
  --quinary-gray-color: #979797;
  --senary-gray-color: #9192a0;

  --dark-gray-color: #5a5f62;
  --secondary-dark-gray-color: #707070;
  --tertiary-dark-gray-color: #979797;

  --light-gray-color: #f7f6f8;
  --medium-gray-color: #a9a9a9;
  --secondary-light-gray-color: #eeeeee;
  --tertiary-light-gray-color: #e0e0e0;
  --quaternary-light-gray-color: #d3d3d9;

  --primary-red-color: #f45352;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* FONT FACE FOR JOST */
@font-face {
  font-family: "Jost-Regular";
  src: url("./assets/fonts/Jost/static/Jost-Regular.ttf");
}

@font-face {
  font-family: "Jost-Bold";
  src: url("./assets/fonts/Jost/static/Jost-Bold.ttf");
}

@font-face {
  font-family: "Jost-Medium";
  src: url("./assets/fonts/Jost/static/Jost-Medium.ttf");
}

@font-face {
  font-family: "Jost-Light";
  src: url("./assets/fonts/Jost/static/Jost-Light.ttf");
}

@font-face {
  font-family: "Jost-Italic";
  src: url("./assets/fonts/Jost/static/Jost-Italic.ttf");
}

@font-face {
  font-family: "Jost-LightItalic";
  src: url("./assets/fonts/Jost/static/Jost-LightItalic.ttf");
}

@font-face {
  font-family: "Jost-semiBold";
  src: url("./assets/fonts/Jost/static/Jost-SemiBold.ttf");
}

@font-face {
  font-family: "Sea-Side";
  src: url("./assets/fonts/Seaside.ttf");
}

html,
body {
  margin: 0;
  font-family: "Jost-Regular", sans-serif;
  font-size: 100%;
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-color: var(--white-color);
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
}

h1,
h2,
h3 {
  font-weight: normal;
}

#root {
  height: 100vh;
  min-height: 100vh;
}

/* SCROLL BAR CUSTOMIZATION STYLES */
::-webkit-scrollbar {
  background: var(--tertiary-light-gray-color);
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-theme-color);
  border-radius: 6px;
}

/* Added this to remove the ripple effect for infra card in Maps section */
.MuiRadio-root:hover {
  background-color: transparent;
}

.marquee-container::-webkit-scrollbar {
  display: none !important;
}

/* TEXTFIELD AUTOFILL BACKGROUND */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  background: none;
}

input::placeholder {
  letter-spacing: normal;
  font-family: "Jost-Regular";
}

.main-container {
  min-height: 100%;
  padding: 20px 50px;
  max-width: inherit;
}

/* BUTTON RELATED STYLES  */
.btn {
  color: var(--white-color);
  border-radius: 6px;
  height: 50px;
  text-transform: capitalize;
  font-family: "Jost-Medium";
  font-size: 1rem;
}

.btn-dark,
.btn-dark:hover {
  background-color: var(--black-color);
  border: 1px solid var(--black-color);
}

.btn-light,
.btn-light:hover {
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
  font-family: "Jost-Medium";
  text-align: center;
  color: var(--black-color);
}

.btn-gray,
.btn-gray:hover {
  background-color: #686868;
  border: 1px solid #686868;
  font-family: "Jost-Medium";
  text-align: center;
  color: var(--white-color);
}

.btn-disabled {
  border: none;
  background-color: var(--quaternary-light-gray-color);
  color: var(--white-color) !important;
}

.close-icon {
  float: right;
  cursor: pointer;
  color: var(--primary-red-color);
}

/* .MuiButtonBase-root.Mui-disabled {
  color: var(--white-color);
} */

.MuiButtonBase-root.MuiButton-text:hover {
  background-color: transparent;
}

.section-heading-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.section-heading-holder>h1 {
  font: 500 1.875rem "Jost-Medium";
}

.margin-top-15px,
.margin-top-15 {
  margin-top: 15px;
}

.margin-top-30px,
.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40px,
.margin-top-40 {
  margin-top: 40px;
}

.margin-top-55px,
.margin-top-55 {
  margin-top: 55px;
}

.read-notification {
  box-shadow: none;
}

.unread-notification {
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.1);
}

.section-holder {
  margin-top: 50px;
}

@media only screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 2px;
  }

  .section-heading-holder {
    margin-bottom: 0.5rem;
  }

  .section-heading-holder>h1 {
    font-size: 1rem;
  }
}