.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  border: solid 1px var(--quaternary-light-gray-color);
  padding: 20px 25px;
}

.index-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  border: solid 1px var(--quaternary-light-gray-color);
  padding: 20px 25px;
  border-bottom: none;
}

.menu-info-holder {
  display: flex;
  align-items: center;
}

.menu-info-holder > img {
  height: 30px;
  width: 40px;
  object-fit: contain;
  margin-right: 30px;
}

.menu-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu-info > h3 {
  font-family: "Jost-Medium";
  font-size: 1.125rem;
  color: var(--quaternary-black-color);
}

.menu-info > p {
  font-family: "Jost-Regular";
  font-size: 1rem;
  color: var(--senary-gray-color);
  margin-right: 20px;
}

@media only screen and (max-width: 768px) {
  .east-icon {
    font-size: 1rem;
  }

  .menu-info-holder > img {
    height: 20px;
    width: 30px;
    margin-right: 10px;
  }

  .menu-info > h3 {
    font-size: 0.93rem;
  }

  .menu-info > p {
    font-size: 0.82rem;
  }

  .index-menu,
  .menu {
    padding: 15px 20px 15px 5px;
  }
}
