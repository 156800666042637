.login-holder {
  width: 100%;
  height: 100%;
  background-color: var(--primary-theme-color);
}

.form-holder {
  width: 100%;
  height: 100%;
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.logo {
  width: 200px;
  height: auto;
}

.right-split {
  height: 100%;
  width: 45%;
  float: right;
  background-color: var(--white-color);
  padding: 60px;
}

.left-split {
  height: 100%;
  width: 55%;
  float: left;
  background-color: var(--primary-theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form-heading {
  font: normal 1.875rem "Jost-SemiBold";
  color: var(--primary-theme-color);
}

.form-textfield {
  margin-top: 45px;
}

.form-textfield-hint {
  margin-left: 0px;
  font-family: "Jost-Regular";
  line-height: 1.11;
  color: var(--senary-gray-color);
  margin-top: 15px;
}

.form-textfield-hint>span {
  font-weight: 600;
}

.otp-instruction-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
}

.otp-instruction-text {
  font-family: "Jost-Regular";
  font-size: 1.25rem;
  line-height: 1;
  color: #1c1c1c;
}

.otp-instruction-text>span {
  color: var(--primary-theme-color);
  font-family: "Jost-Medium";
  cursor: pointer;
  text-decoration: underline;
  margin-left: 6px;
}

.highlight-text {
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}

.otp-instruction-holder>span {
  font-family: "Jost-Regular";
  font-size: 1.125rem;
  line-height: 1.11;
  cursor: pointer;
  text-decoration: underline;
  color: var(--primary-theme-color);
}

.switch-holder {
  margin-top: 50px;
  display: flex;
  align-items: center;
}

.active-allow-whatsapp-text {
  color: var(--quaternary-black-color);
  font-size: 1.125rem;
  margin-left: 10px;
  font-family: "Jost-Medium";
}

.inactive-allow-whatsapp-text {
  color: var(--senary-gray-color);
  font-size: 1.125rem;
  margin-left: 10px;
  font-family: "Jost-Regular";
}

.resend-otp-btn-holder {
  position: relative;
}

.resend-otp-btn {
  font-family: "Jost-Bold";
  font-size: 12px;
  text-align: right;
  color: var(--primary-theme-color);
  width: 110px;
  cursor: pointer;
  background-color: var(--white-color);
  border: none;
}

.resend-otp-btn-disable {
  font-family: "Jost-Regular";
  font-size: 12px;
  text-align: right;
  color: #969696;
  width: 110px;
  cursor: not-allowed;
  background-color: var(--white-color);
  border: none;
}

.resend-otp {
  margin-top: 30px;
  text-align: center;
  color: var(--senary-gray-color);
  font-family: "Jost-Regular";
}

.resend-otp span {
  color: #000000;
}

.btn-holder {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-holder>button {
  padding: 0px 60px;
}

.terms-condition-privacy-policy-text {
  margin-top: 20px;
  line-height: 30px;
  font-family: "Jost-Regular";
  font-size: 1.25rem;
  text-align: center;
}

.terms-condition-privacy-policy-text span {
  margin: 0px 5px;
  text-decoration: underline;
  cursor: pointer;
}

.trouble-signing-in-holder {
  margin-top: 70px;
  text-align: center;
}

.trouble-signing-in {
  font-size: 1.125rem;
  color: var(--senary-gray-color);
  text-decoration: underline;
  cursor: pointer;
  background-color: var(--white-color);
  border: none;
  font-family: "Jost-Medium";
}

.stories-heading {
  color: white;
  font-size: 1.6rem;
  font-family: "Jost-Medium";
  text-align: center;
  margin-bottom: 50px;
  /* margin-top: 10px; */
}

.preposition-holder {
  padding: 20;
  background-color: var(--primary-theme-color);
}

.preposition-icon {
  object-fit: contain;
  width: 100%;
}

.preposition-icon-three {
  height: 600px;
  width: 100%;
}

@media only screen and (min-width: 1100px) and (max-width: 1450px) {
  .preposition-icon {
    min-height: 480px;
    width: 100%;
    padding: 0 30px;
  }

  .preposition-icon-three {
    height: 400px;
  }
}

@media only screen and (min-height: 320px) and (min-width: 830px) and (max-height: 330px) and (max-width: 840px) {
  .preposition-icon {
    max-height: 300px;
  }

  .stories-heading {
    font-size: 1.2rem;
    margin-top: 80px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1700px) {
  .stories-heading {
    font-size: 1.2rem;
    margin-top: 0px;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {

  .stories-heading {
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 1700px) {
  .stories-heading {
    font-size: 1.25rem;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .login-holder {
    width: 100%;
    height: 100%;
  }

  .form-heading {
    font-size: 1.375rem;
  }

  .left-split {
    height: 20%;
    width: 100%;
    background-color: var(--primary-theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .right-split {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    height: 80%;
    width: 100%;
    padding: 40px 30px;
  }

  .logo {
    width: 150px;
    height: auto;
  }

  .switch-holder {
    margin-top: 20px;
  }

  .form-textfield-hint {
    font-size: 0.75rem;
  }

  .active-allow-whatsapp-text {
    font-size: 0.875rem;
  }

  .inactive-allow-whatsapp-text {
    font-size: 0.875rem;
  }

  .terms-condition-privacy-policy-text {
    font-size: 0.875rem;
    line-height: 22px;
  }

  .trouble-signing-in-holder {
    margin-top: 60px;
    text-align: center;
  }

  .trouble-signing-in {
    text-align: center;
    font-size: 1rem;
  }

  .resend-otp-btn {
    width: 120px;
  }

  .otp-instruction-text,
  .otp-instruction-holder>span {
    font-size: 0.875rem;
  }
}

@media only screen and (min-width: 1580px) {
  .stories-heading {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}