.apply-now-brn-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.apply-now-brn-holder button {
  width: 60%;
  margin-bottom: 30px;
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .apply-now-brn-holder button {
    width: 100%;
  }
}
