.journey-section-container {
  margin-top: 24px;
  padding: 24px 24px 10px 24px;
  position: relative;
  border-radius: 10px;
  color: grey !important;
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.07);
}

.completed-journey-section-container {
  margin-top: 24px;
  padding: 24px 24px 10px 24px;
  position: relative;
  border-radius: 10px;
  color: grey !important;
}

.journey-completed-section-container {
  margin-top: 24px;
  border: 2px solid var(--quaternary-blue-magenta-color);
  padding: 24px 24px 10px 24px;
  position: relative;
  border-radius: 10px;
  color: grey !important;
}

.disabled-journey-section {
  margin-top: 24px;
  padding: 24px 24px 10px 24px;
  position: relative;
  border-radius: 10px;
  color: rgb(244, 241, 241) !important;
}

.circle-outlined {
  background-color: #88ecbe;
  color: #32ba7c;
  border-radius: 30px;
}

.content-view-option {
  color: #676ac0;
  text-decoration: underline;
  cursor: pointer;
}

.rupee-coin {
  height: 25px;
  width: 25px;
}

.application-date {
  width: 107px;
  height: 24px;
  margin: 2px 0 0 16px;
  font-size: 16.5px;
}

.divider-line {
  margin-top: 10px;
  margin: 0px -24px;
}

.divider-line-grey {
  margin-top: 10px;
  margin: 0px -24px;
  border-color: var(--light-gray-color);
}

.divider-main-container {
  margin-top: 10px;
  margin: 0px -24px;
  border-color: rgb(164, 163, 163);
}

.divider-line-children {
  margin-top: 10px;
  margin: 0px -24px;
  margin-left: -54px;
  border-color: var(--light-gray-color);
}

.disabled-text {
  color: #cececf;
}

.disabled-content-view-option {
  color: #cececf;
  text-decoration: underline;
  cursor: pointer;
}

.typography-title {
  font-size: 1.35rem;
  font-family: "Jost-Medium";
}

.typography-sub-title {
  font-size: 1.1rem;
  min-width: 120px;
}

.small-icon {
  font-size: 1.13rem;
  margin-left: 2px;
}

.line-ellipsis {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-ellipsis-5 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.large-icon {
  font-size: 1.85rem;
  margin-left: -2px;
}

.document-card {
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.07);
}

.view-payment-receipt-btn,
.view-btn,
.click-here-btn {
  font-size: 1.1125rem;
  font-family: "Jost-Regular";
}


@media only screen and (max-width: 768px) {
  .journey-completed-section-container {
    padding: 2px 5px;
  }

  .journey-section-container {
    padding: 2px 5px;
  }

  .divider-line {
    margin: 0px -5px;
  }

  .divider-line-grey {
    margin: 0px 0px;
  }

  .divider-main-container {
    margin: 0px -5px;
  }

  .divider-line-children {
    margin-left: 0px;
  }

  .typography-title {
    font-size: 1rem;
  }

  .typography-sub-title {
    font-size: 0.9rem;
  }

  .large-icon {
    font-size: 1.3rem;
    margin-left: 2px;
  }

  .small-icon {
    font-size: 0.9rem;
    margin-left: 2px;
  }

  .rupee-coin {
    width: 15px;
    height: 15px;
  }

  .view-btn-wrapper {
    width: 80px
  }

  .view-btn {
    font-size: 0.7rem;
    font-family: "Jost-Regular";
    width: 80px;
  }

  .view-payment-receipt-btn {
    font-size: 0.85rem;
    font-family: "Jost-Regular";
  }

  .click-here-btn {
    font-size: 0.65rem;
    min-width: 150px;
  }

  .line-ellipsis-5 {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}