.card-content{
    padding : 15px;
    margin-top: 15px;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.06);
    border-radius: 10px;
}

.typography-title{
    font-size: 1.2rem;
    font-weight: normal;
    font-family: "Jost-Regular"
}

.typography-view{
    color: var(--primary-theme-color);
    font-size: 1.1rem;
    text-decoration: underline;
    font-family: "Jost-Regular"
}

@media only screen and (max-width : 700px){
    .typography-title{
        font-size: 0.9rem;
    }

    .typography-view{
        font-size: 0.8rem;
    }
    .doc-icon-img{
        height: 30px;
        width : 20px
    }
    .card-content{
        padding : 10px 3px 10px 10px;
        margin-top: 10px;
    }
}