.portfolio-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
}

.portfolio-info-holder {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio-right-split {
  width: 50%;
  margin-left: 15px;
}

.portfolio-left-split {
  width: 50%;
  margin-right: 15px;
}

.portfolio-right-split h3 {
  font-family: "Jost-Medium";
  font-size: 20px;
  line-height: 2.39;
  color: var(--black-color);
}

.portfolio-right-split p {
  font-family: "Jost-Regular";
  font-size: 18px;
  line-height: normal;
  color: var(--black-color);
}

.portfolio-item {
  padding: 20px 30px;
  background-image: linear-gradient(114deg, #5d5d73 5%, #343445 93%);
  border-radius: 10px;
}

.heading-holder {
  margin: 1rem 0;
  font-family: "Jost-Medium";
}

@media (min-width: 769px) and (max-width: 1020px) {
  .portfolio-info-holder {
    width: 100%;
  }
  .portfolio-left-split {
    width: 85%;
  }
}
@media only screen and (max-width: 768px) {
  .portfolio-info-holder {
    width: 100%;
    flex-direction: column;
  }

  .portfolio-right-split,
  .portfolio-left-split {
    width: 100%;
    margin: 0px;
  }

  .portfolio-item {
    padding: 20px 20px;
  }

  .portfolio-grid {
    margin: 0px;
  }

  .heading-holder {
    display: none;
  }

  .view-portfolio-btn {
    margin-top: 30px;
    width: 100%;
  }
}
