.section-heading {
  font-family: "Jost-Medium";
  font-size: 1.875rem;
  color: var(--quaternary-black-color);
  margin-bottom: 25px;
  margin-top: 30px;
  text-align: center;
}

.upcoming-infra-card {
  width: 100%;
  height: 250px;
}

.image-title-holder {
  position: relative;
}

.image-title-holder div {
  padding: 8px 15px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.36);
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  border-radius: 0 0 14px 14px;
}

.image-title-holder div p {
  font-family: "Jost-Regular";
  font-size: 1.25rem;
  line-height: 1.03;
  color: var(--white-color);
  border-radius: 0 0 14px 14px;
}

.upcoming-infra-card img {
  border-radius: 14px;
  object-fit: cover;
  width: 100%;
  height: 180px;
}

.upcoming-infra-card > p {
  padding-top: 8px;
  font-size: 1.125rem;
  font-family: "Jost-Light";
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
}

@media only screen and (max-width: 768px) {
  .upcoming-infra-card {
    border-bottom: 1px solid gainsboro;
  }

  .image-title-holder div p {
    font-size: 1rem;
  }

  .upcoming-infra-card > p {
    font-size: 1rem;
  }

  .section-heading {
    font-size: 1rem;
    margin-bottom: 20px;
    margin-top: 15px;
    text-align: left;
  }
}
