.promise-full-banner {
  width: 95%;
  margin: 1rem auto;
  height: auto;
}
.about-promise-holder {
  margin-top: 50px;
}

.about-promise-holder > h2,
.promises-carousel-holder > h2 {
  font-family: "Jost-Medium";
  font-size: 1.625rem;
  line-height: 1.03;
  color: #17171c;
}

.about-promise-holder p {
  font-family: "Jost-Light";
  font-size: 1.5rem;
  line-height: 1.65;
  color: var(--black-color);
  margin-top: 20px;
}

.promises-carousel-holder {
  margin-top: 50px;
}

.promises-carousel-holder > h2 {
  margin-bottom: 30px;
}

.carousel-items {
  width: 300px !important;
  margin: 0 1rem;
}
/* Promise Card Styles*/
.promise-item {
  height: 250px;
  background-image: linear-gradient(to bottom, #f0f0ff, #e7e8ff);
  border-radius: 10px;
  padding: 20px 15px;
  cursor: pointer;
}

.promise-item > img {
  height: 35px;
  width: auto;
  object-fit: contain;
  margin-bottom: 20px;
}

.promise-item > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.promise-item > div > h2 {
  font-family: "Jost-Bold";
  font-size: 22px;
  font-weight: bold;
  color: #494a67;
}

.promise-item > p {
  font-family: "Jost-Regular";
  font-size: 18px;
  color: #17171c;
}

.disclaimer-text {
  margin: 2rem 0 1rem;
  text-align: center;
  font-family: "Jost-Light";
  color: var(--senary-gray-color);
}
@media (min-width: 1440px) {
  .about-promise-holder h2 {
    font-size: 28px;
  }
  .promises-carousel-holder > h2 {
    font-size: 25px;
  }
}
@media (min-width: 460px) and (max-width: 630px) {
  .about-promise-holder h2,
  .promises-carousel-holder > h2 {
    font-size: 20px;
  }
  .about-promise-holder p {
    font-size: 15px;
  }
}

@media (max-width: 459px) {
  .promise-full-banner {
    width: 100%;
  }
  .promise-banner {
    padding: 20px 30px;
    margin-right: 0px;
    margin-left: 0px;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    margin-top: 10px;
  }
  .promise-banner h2,
  .about-promise-holder h2,
  .promises-carousel-holder h2 {
    font-size: 1rem;
  }
  .promise-banner p,
  .about-promise-holder p {
    font-size: 1rem;
  }
  .promise-banner img {
    height: 65px;
  }
}
