.section-heading {
    font-size: 1.3rem;
    font-family: "Jost-semiBold";
}

.typography-heading-2 {
    font-size: 1.25rem;
    font-family: "Jost-Medium";
}

.typography-sub-heading {
    font-family: "Jost-Medium";
    font-size: 1.1125rem;
    margin-top: 8px;
}

.typography-description {
    font-size: 1.1rem;
    font-family: "Jost-Light";
}

.line-ellipsis {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 768px) {
    .typography-heading-2 {
        font-size: 1.3rem;
    }

    .section-heading {
        color: var(--primary-theme-color);
    }

    .typography-sub-heading {
        font-family: "Jost-Medium";
        font-size: 1.2rem;
        margin-top: 8px;
    }

    .typography-description {
        font-size: 1rem;
        font-family: "Jost-Light";
        line-height: 1.6;
    }
}