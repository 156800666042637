.map-holder {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  position: relative;
}

.map {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  height: 600px;
}

.open-map-btn {
  position: absolute;
  bottom: 40px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (max-width: 768px) {
  .map {
    height: 200px;
    border-radius: 10px;
  }

  .open-map-btn {
    bottom: 20px;
  }
}
