.heading {
  font-family: "Jost-Medium";
  font-size: 1.625rem;
  text-align: center;
  color: #383838;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 15px 0;
}
.dialog-title {
  text-align: center;
  font-family: "Jost-Medium";
  margin-bottom: 2rem;
}
.close-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.social-icons {
  width: 90px;
  height: 90px;
}
.social-media-logo {
  object-fit: contain;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-copy-btn-holder {
  background-color: #f3f3f3;
  padding-left: 10px;
  border-radius: 8px;
  margin: 2rem 1.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link-copy-btn-holder p {
  font-family: "Jost-Regular";
  font-size: 1.25rem;
  color: #9795af;
}

.link-copy-btn-holder button,
.link-copy-btn-holder button:hover {
  font: normal 1.375rem "Jost-Medium";
  color: var(--white-color);
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 0 8px 8px 0;
  background-color: var(--primary-theme-color);
}

@media only screen and (max-width: 768px) {
  .heading {
    font-size: 1.25rem;
  }

  .close-icon {
    margin-right: 5px;
    margin-top: 0px;
  }
  .social-icons {
    width: 35px;
    height: 35px;
    margin-bottom: 1rem;
  }

  .link-copy-btn-holder {
    margin: 1rem 0.5rem;
  }
  .link-copy-btn-holder p {
    font-family: "Jost-Regular";
    font-size: 1rem;
    color: #9795af;
  }

  .link-copy-btn-holder button,
  .link-copy-btn-holder button:hover {
    font-family: "Jost-Medium";
    font-size: 1.125rem;
    padding-left: 20px;
    padding-right: 20px;
  }
}
