.story-content-image {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    object-fit: cover;
    border-radius: 10px;
}

.z-index {
    z-index: 100;
    padding: 10px;
}

.dialog-content {
    min-height: 95vh;
    align-items: center;
    align-content: center;
    text-align: center;
    background-color: white;
    padding: 10px 0;
}

.typography-title-text {
    font-size: 1.3rem;
    font-family: "Jost-semiBold";
    margin-top: 10px;
}

.typography-title-sub-text-italic {
    font-size: 1.1rem;
    font-family: "Jost-Regular";
}

.line-ellipsis {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.active-stepper {
    width: 100%;
    height: 2.5px;
    background-color: var(--primary-theme-color);
    border-radius: 20px;
}

.inactive-stepper {
    width: 100%;
    ;
    height: 2.5px;
    background-color: rgb(85, 85, 85);
    background-color: var(--senary-blue-magenta-color);
    border-radius: 20px;
}

.left-arrow {
    width: 60px;
    height: 60px;
    margin-top: 40vh;
    position: fixed;
    top: 0;
    padding: 8px;
    background-color: var(--white-color);
    border-radius: 50%;
    color: black;
}

.right-arrow {
    width: 60px;
    height: 60px;
    margin-top: 40vh;
    position: fixed;
    top: 0;
    right: 1;
    padding: 8px;
    background-color: var(--white-color);
    border-radius: 50%;
    color: black;
}

.stories-content-section {
    min-height: 60vh;
    min-width: 100%;
    padding: 10px 30px;
}

.stories-details-content {
    font-size: 1.25rem;
    font-family: "Jost-Regular";
}

@media only screen and (max-width:768px) {
    .story-content-image {
        width: 100%;
        height: 100%;
    }

    .typography-title-text {
        font-size: 1.1rem;
    }

    .typography-title-sub-text-italic {
        font-size: 0.9rem;
    }

    .stories-content-section {
        padding: 5px 15px;
    }

    .stories-details-content {
        font-size: 0.9rem;
    }
}

@media only screen and (min-width:768px) {
    .stories-heading-content {
        margin-left: 15px;
    }
}