.footer-dimension {
  background-color: var(--primary-theme-color);
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  padding: 8px 20px;
}

.footer-dimension ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-dimension ul > a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Jost-Regular";
  font-size: 0.75rem;
  text-align: center;
  color: #bec0ff;
  text-decoration: none;
}

.footer-dimension ul > a > img {
  height: 18px;
  object-fit: contain;
  width: auto;
  margin-bottom: 5px;
}

.footer-dimension a:active,
.menu-item-active {
  color: var(--white-color);
  font-family: "Jost-Bold";
}

.active-menu-text {
  color: var(--white-color);
}

@media only screen and (min-width: 890px) {
  .footer-dimension {
    display: none;
  }
}
