.nav-back-icon{
    z-index: 1000;
}

@media only screen and (max-width: 768px){
    .media-content-wrapper{
        position: absolute;
        top: 20;
        left: 5;
    }
}