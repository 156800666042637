.section-heading {
  font-family: "Jost-Medium";
  font-size: 1.875rem;
  color: var(--quaternary-black-color);
  margin-bottom: 25px;
  margin-top: 30px;
}

.tourism-around-card {
  width: 100%;
  padding: 14px;
  border-radius: 14px;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white-color);
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.tourism-around-card img {
  margin-right: 15px;
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 10px;
}

.tourism-around-card h2 {
  font-family: "Jost-Medium";
  font-size: 1.5rem;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.72);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tourism-around-card p {
  font-family: "Jost-Regular";
  font-size: 1.5rem;
  line-height: 1.33;
  color: #494a67;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collapse-icon {
  transform: rotate(180deg);
}

@media only screen and (max-width: 768px) {
  .tourism-around-card h2 {
    font-size: 1rem;
  }

  .tourism-around-card p {
    font-size: 1rem;
  }

  .section-heading {
    font-size: 1rem;
    margin-bottom: 20px;
    margin-top: 25px;
    text-align: left;
  }
}
