.header-nav {
  background-color: var(--primary-theme-color);
  flex-direction: row;
  padding: 1rem;
  column-gap: 1rem;
}

.second-div {
  overflow-x: hidden;
  width: 100%;
}

.logo {
  width: auto;
  height: 98px;
  width: auto;
  cursor: pointer;
  margin: 0 0.5rem;
}

.logo-mobile {
  display: none;
}

.masthead {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 14px 20px;
  background-image: linear-gradient(to left, #32369f, #212365);
  /* flex-basis: 75%; */
  overflow: hidden;
  margin-right: 20px;
  justify-content: space-between;
  cursor: pointer;
}

.heading {
  font-family: "Jost-Regular";
  font-size: 1rem;
  line-height: 0.89;
  letter-spacing: normal;
  color: #cacbed;
  margin-left: 2rem;
}

.value {
  font: bold 1.125rem/0.67 "Jost-Bold";
  letter-spacing: normal;
  color: var(--white-color);
}

.masthead-app-store-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.masthead div {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
.marquee-wrapper {
  height: 22px;
}
.store-icons {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.nav-menu-icons {
  height: 18px;
  width: auto;
  object-fit: contain;
  margin-right: 10px;
}

.download-app-heading {
  /* margin-right: 10px; */
  font: 500 1rem/1 "Jost-Medium";
  color: var(--white-color);
}

.menu-items-search-holder {
  display: flex;
  align-items: center;
}

.menu-items-search-holder ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-items-search-holder li {
  list-style: none;
}

.icon-btn {
  background-color: #5a5da9;
  border-radius: 6px;
  margin-left: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.search-field {
  /* flex-grow: 1.5; */
  flex-basis: 30%;
  background-color: white;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

a {
  display: flex;
  /* align-items: center; */
  font: normal 16px "Jost-Regular";
  text-align: center;
  color: #bec0ff;
  text-decoration: none;
}

a:active,
.menu-item-active {
  color: var(--white-color);
  font-family: "Jost-Bold";
}

.mobile-menu,
.mobile-second-div {
  display: none;
}
.nav-icons-holder {
  display: flex;
  column-gap: 1rem;
}
.nav-icons {
  color: #bec0ff;
}
.nav-icons-active {
  color: var(--white-color);
}

@media only screen and (max-width: 600px) {
  .header-nav {
    padding: 0.25rem 0rem 1rem 0rem;
  }

  .logo {
    display: none;
  }

  .logo-mobile {
    display: block;
    width: auto;
    height: 30px;
    margin-right: 20px;
  }

  .nav-icons-holder {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .row {
    display: none;
  }

  .masthead {
    /* display: flex;
    align-items: center; */
    border-radius: 0px;
    padding: 8px 10px;
    margin-right: 0px;
    /* background-image: linear-gradient(to left, #32369f, #212365); */
    /* flex-grow: 2;
    margin-right: 20px;
    justify-content: space-between; */
  }

  .heading {
    font-size: 0.687rem;
    margin-right: 10px;
    min-width: 50px;
  }

  .value {
    font-size: 0.75rem;
    margin-right: 10px;
  }

  nav {
    display: none;
  }

  .second-div {
    margin-left: 0px;
  }

  .mobile-menu {
    display: block;
  }

  .nav-icons {
    font-size: 1.5rem;
  }

  .search-field {
    margin-left: 10px;
  }
}
