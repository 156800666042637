.main-graph-content {
    max-height: 650px;
    max-width: 95%;
}

.box-shadow {
    box-shadow: 0 9px 24px 0 rgba(0, 0, 0, 0.1);
    padding: 5px;
    border-radius: 10px;
}

@media only screen and (max-width:600px){
    .main-graph-content {
        max-height: 300px;
        max-width: 100%; 
    }
}