.page {
  width: 100%;
  min-height: calc(100% - 91px);
  background-color: var(--secondary-gray-color);
  padding: 50px 50px;
  top: 91px;
  position: relative;
}

.root {
  display: flex;
}

.main-container {
  margin: 30px;
  padding: 30px;
  border-radius: 25px;
  background-color: var(--white-color);
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.04);
}

.home-container {
  margin: 30px;
  border-radius: 25px;
  background-color: var(--primary-theme-color);
}

@media only screen and (max-width: 820px) {
  .page {
    padding: 28px 0px 0px 0px;
  }

  .main-container {
    padding: 15px 15px 55px;
    border-radius: 25px 25px 0px 0px;
  }

  .main-container,
  .home-container {
    margin: 0;
  }
}
