.heading-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.heading-holder>h2 {
  font-family: "Jost-semiBold";
  font-size: 1.5rem;
  line-height: 1.6;
  color: #16161c;
}

.profile-details-holder {
  margin-bottom: 50px;
}

.profile-picture-heading {
  font-family: "Jost-Regular";
  font-size: 1.25rem;
  /* line-height: 1.02; */
  letter-spacing: normal;
  color: #16161c;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-left: 5px;
}

.profile-pic-holder {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.profile-image {
  font: normal 2rem "Jost-semiBold";
  width: 100px;
  height: 100px;
  background-color: var(--primary-theme-color);
  border: 3px solid var(--primary-theme-color);
}

.profile-image img {
  object-fit: cover;
}

@supports (selector(:has(img))) {
  .profile-image:not(:has(img))::before {
    content: "";
    position: absolute;
    border-radius: 50px;
    width: 90px;
    height: 90px;
    border: 3px solid #ffffff;
  }
}

.profile-pic-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.profile-pic-actions button,
.profile-pic-actions label {
  font-family: "Jost-Regular";
  font-size: 0.9rem;
  color: var(--senary-gray-color);
}

.profile-pic-actions img {
  height: 18px;
  margin-right: 10px;
  width: auto;
  object-fit: contain;
}

.personal-details-heading {
  font-family: "Jost-Regular";
  font-size: 1.25rem;
  color: #16161c;
  margin-bottom: 20px;
}

.personal-details-holder {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  grid-column-gap: 50px;
}

.personal-details-holder span {
  font-family: "Jost-Regular";
  font-size: 1rem;
  color: var(--senary-gray-color);
}

.personal-details-holder h3 {
  font-family: "Jost-Medium";
  font-size: 1.125rem;
  color: var(--quaternary-black-color);
}

.communication-address-heading {
  font-family: "Jost-Regular";
  font-size: 22px;
  line-height: 1.02;
  letter-spacing: normal;
  color: #16161c;
  margin-top: 40px;
  margin-bottom: 30px;
}

.profile-pic-actions .MuiButtonBase-root.Mui-disabled {
  color: var(--senary-gray-color) !important;
}

.profile-pic-loader {
  position: absolute;
}

@media only screen and (max-width: 768px) {

  .profile-picture-heading,
  .personal-details-heading {
    margin-top: 20px;
    padding: 0;
  }

  .communication-address-heading {
    margin-top: 40px;
  }

  .profile-picture-heading,
  .personal-details-heading,
  .communication-address-heading {
    font-size: 1.125rem;
  }

  .personal-details-holder h3 {
    font-size: 1rem;
  }

  .heading-holder>h2 {
    font-size: 1.3rem;
    line-height: 1.6;
    color: var(--primary-theme-color);
  }
}