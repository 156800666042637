.banner {
  position: relative;
}

.banner-heading-holder {
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.banner-heading,
.banner-subheading {
  color: var(--white-color);
  text-align: center;
  text-transform: uppercase;
}

.banner-heading {
  font-family: "Jost-SemiBold";
  font-size: 2.125rem;
}

.banner-subheading {
  font-family: "Jost-Regular";
  font-size: 1.75rem;
}

.banner-subheading::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: auto;
  width: 15%;
  border-bottom: 10px solid var(--primary-theme-color);
  border-radius: 15px;
}

.banner img {
  margin-top: 15px;
  height: 400px;
  width: 100%;
  object-fit: cover;
  border-radius: 25px 25px 0px 0px;
}

.banner-overlay {
  position: absolute;
  bottom: 0;
  top: 15px;
  left: 0;
  right: 0;
  width: 100%;
  height: 400px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  transition: 0.5s ease;
  opacity: 1;
  border-radius: 25px 25px 0px 0px;
}

@media only screen and (max-width: 768px) {
  .banner img {
    height: 150px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
  }

  .banner-heading {
    font-size: 1.25rem;
  }

  .banner-subheading {
    font-size: 1rem;
  }

  .banner-overlay {
    height: 150px;
    border-radius: 10px 10px 0px 0px;
  }

  .banner-subheading::after {
    width: 20%;
    bottom: -15px;
    border-bottom: 6px solid var(--primary-theme-color);
    border-radius: 15px;
  }
}
