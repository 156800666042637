.card-content {
    background-color: #ebebf0;
    text-align: center;
    align-content: space-evenly;
    border-radius: 5px;
}

.no-data-icon-section {
    padding: 15px;
    border-radius: 200px 0 0 200px;
    background-color: rgba(255, 255, 255, 0.51);
    height: 160px;
    align-items: center;
    display: flex;
    justify-content: center;
}


.no-data-message-section {
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 20px 30px 20px;
}

.no-data-message-section-with-align {
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 20px 30px 20px;
    justify-content: flex-start;
}

.no-data-message {
    color: #878790;
    font-size: 1.2rem;
}

@media only screen and (max-width:700px) {
    .no-data-icon-section {
        padding: 20px;
    }

    .no-data-message {
        color: #878790;
        font-size: 0.9rem;
    }

    .no-data-icon {
        min-height: 50px;
        min-width: 50px;
    }

    .no-data-message-section {
        justify-content: flex-start;
        text-align: start;
        padding: 0px 0px 0px 10px;
    }

}