.section-heading-name,
.section-heading-name-2 {
    font-size: 1.45rem;
    font-family: "Jost-semiBold"
}

.left-arrow {
    cursor: pointer;
}

.typography-title {
    font-size: 1.3rem;
    font-family: "Jost-semiBold"
}

.typography-title-2 {
    font-size: 1.3rem;
    font-family: "Jost-Medium"
}

.typography-heading {
    font-size: 1.2rem;
    font-family: "Jost-Regular"
}

.typography-sub-heading-3 {
    font-size: 1rem;
    font-family: "Jost-Regular";
}

.line-ellipsis {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.typography-success {
    color: #47b35f;
    font-size: 1.1rem;
    font-family: "Jost-Regular";
}

.heading-divider {
    border-color: var(--secondary-light-gray-color);
    margin-top: 10px;
}

.section-heading-name-3 {
    font-size: 1.1rem;
    font-family: "Jost-semiBold";
}

.disclaimer-section {
    margin: 40px 0 30px 0;
    text-align: center;
    padding: 10px
}

.disclaimer-text {
    color: var(--senary-gray-color)
}


@media only screen and (max-width: 768px) {
    .section-heading-name {
        font-size: 1.3rem;
        /* color: var(--primary-theme-color) */
    }

    .typography-title-2,
    .typography-title {
        font-size: 1rem;
    }

    .section-heading-name-2 {
        font-size: 1rem
    }

    .section-heading-name-3 {
        font-size: 0.75rem;
    }

    .typography-heading {
        font-size: 0.85rem;
    }

    .typography-sub-heading-3 {
        font-size: 0.65rem;
    }

    .typography-success {
        font-size: 0.85rem;
    }

    .disclaimer-section {
        margin: 10px 0;
    }
}