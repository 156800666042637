.testimonial-card {
  width: 100%;
  min-height: 100px;
  padding: 30px 40px;
  border-radius: 24px;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.06);
  background-color: var(--white-color);
  margin-bottom: 30px;
}

.testimonial-card > h2 {
  color: var(--senary-theme-color);
  font-family: "Jost-Regular";
  font-size: 1.5rem;
  text-align: center;
}

.testimonial-card > div {
  font-family: "Jost-Regular";
  font-size: 1.375rem;
  text-align: center;
  color: var(--black-color);
}

.testimonial-card > p {
  font-family: "Jost-Light";
  font-size: 1.375rem;
  line-height: 1.74;
  text-align: center;
  color: var(--black-color);
  padding-top: 10px;
}

@media only screen and (max-width: 768px) {
  .testimonial-card {
    padding: 10px 20px;
    border-radius: 10px;
  }

  .testimonial-card > h2 {
    font-size: 1.125rem;
  }

  .testimonial-card > div {
    font-size: 1rem;
  }

  .testimonial-card > p {
    font-size: 1rem;
    line-height: 1.3;
  }
}
