.opportunity-doc-holder {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}

.image-banner {
  width: 50%;
  height: auto;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .image-banner {
    width: 100%;
  }
}
