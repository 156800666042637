.section-heading {
    font-size: 1.5rem;
    font-family: "Jost-semiBold";
}

.details-container {
    margin-top: 20px;
}

.typography-medium {
    font-size: 1.3rem;
    font-family: "Jost-Regular";
}

.typography-small {
    font-size: 1.1rem;
    color: #3f416d
}

.icon-list-content {
    display: flex;
    justify-content: space-around;
}

/* icon cards  */
.icon-card-section {
    padding: 10px 10px;
    cursor: pointer;
}

.icon-card-image {
    width: 38px;
    height: 38px;
}

.icon-card-active-image {
    width: 47px;
    height: 47px;
    background-color: rgba(246, 194, 4, 0.982);
    box-shadow: 2px 2px 12px 0 rgba(54, 53, 51, 0.5);
    border-radius: 50%;
}

.image-title-text-active {
    font-size: 1.2rem;
    font-family: "Jost-Medium";
}

.image-title-text {
    font-size: 1.2rem;
    font-family: "Jost-Light";
    color: var(--senary-gray-color);
}

.check-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.check-text-title {
    color: var(--senary-gray-color);
    font-family: "Jost-Regular";
}

.typography-small-text {
    font-size: 1.2rem;
}


@media only screen and (max-width : 800px) {
    .section-heading {
        font-size: 1.1rem;
        color: var(--primary-theme-color)
    }

    .heading-container {
        margin-top: 10px;
    }

    .details-container {
        padding-left: 5px;
    }

    .typography-medium {
        font-size: 1.2rem;
    }

    .typography-small {
        font-size: 1rem;
    }

    .icon-card-image {
        width: 30px;
        height: 30px;
    }

    .icon-card-active-image {
        width: 40px;
        height: 40px;
    }

    .image-title-text {
        font-size: 0.7rem
    }

    .image-title-text-active {
        font-size: 0.85rem
    }

    .icon-list-content {
        justify-content: center;
        text-align: center;
        row-gap: 4;
        margin-left: -25px;
    }

    .icon-card-content {
        width: 50px;
        box-shadow: #3f416d;
    }

    .icon-card-section {
        padding: 4px 10px;
    }

    .check-section {
        flex-direction: column;
        justify-content: space-around;

    }

    .typography-small-text {
        font-size: 0.9rem;
    }
}