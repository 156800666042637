.section-heading-name {
    font-size: 1.4rem;
    font-weight: bold;
}

.section-spacing {
    margin-top: 20px;
}

.container-padding {
    padding: 10px;
}

.typography-theme-text {
    color: var(--primary-theme-color);
    font-size: 1.3rem;
    font-weight: bold;
}

.typography-text-bold {
    font-size: 1rem;
    font-weight: bold;
    color: var(--black-color);
}

.title-text {
    font-size: 1.3rem;
    margin-left: 4px;
}

.see-all-btn {
    color: var(--primary-theme-color);
    font-size: 1.1rem;
    text-transform: uppercase;
}

.see-all-receipt-btn {
    text-decoration: underline;
    color: var(--primary-theme-color);
    font-size: 1.1rem;
}

.account-title-text {
    font-size: 1.3rem;
    margin-left: 4px;
}

.left-arrow {
    cursor: pointer;
}


@media only screen and (max-width:700px) {
    .section-heading-name {
        font-size: 1.1rem;
        color: var(--primary-theme-color);
    }

    .page-heading {
        margin-top: 25px;
    }

    .typography-theme-text {
        font-size: 1rem;
    }

    .typography-text-bold {
        font-size: 0.8rem;
    }

    .title-text {
        font-size: 1rem;
    }

    .see-all-btn,
    .see-all-receipt-btn {
        font-size: 0.8rem;
    }

    .account-title-text {
        font-size: 1rem;
    }
}