.location-infra-card {
  background-color: var(--white-color);
  padding: 15px 20px;
  border-radius: 14px;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  /* width: 100%; */
  margin-bottom: 10px;
  cursor: pointer;
  /* width: 350px; */
}

.location-infra-card>div>div {
  width: 200px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1rem;
  font-family: 'Jost-Regular';
}

.selected-location-infra-card {
  border: 1px solid var(--primary-theme-color);
}

.location-infra-card img {
  height: 40px;
  width: 40px;
  object-fit: contain;
  margin-right: 20px;
}

.location-infra-card div {
  display: flex;
  flex-direction: column;
}

.location-infra-card h3 {
  font-family: "Jost-Bold";
  font-size: 1.1125rem;
  color: var(--quaternary-black-color);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
}

.location-infra-card span {
  font-family: "Jost-Regular";
  font-size: 1rem;
  color: var(--quaternary-black-color);
}

@media only screen and (max-width: 768px) {
  .location-infra-card {
    padding: 10px 10px;
    border-radius: 10px;
  }

  .location-infra-card img {
    height: 20px;
    margin-right: 10px;
  }

  .location-infra-card h3 {
    font-size: 1rem;
  }

  .location-infra-card span {
    font-size: 0.9rem;
  }

  .location-infra-card {
    width: 220px;
  }

  .location-infra-card>div>div {
    width: 170px;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .location-infra-card>div>div {
    width: 160px;
    font-size: 0.8rem;
  }

  .location-infra-card h3 {
    font-size: 0.9rem;
  }

  .location-infra-card {
    width: 250px;
  }
}