.section-heading {
  text-align: center;
  width: 60%;
  margin-top: 50px;
}

.about-project-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.about-project-card {
  width: 70%;
  min-height: 400px;
  margin-top: 30px;
}

.about-project-card img {
  border-radius: 20px;
  object-fit: cover;
  width: 100%;
  height: 250px;
}

.about-project-card p {
  padding-top: 8px;
  padding-bottom: 10px;
  font-size: 1.375rem;
  font-family: "Jost-Light";
  color: #313131;
}

@media only screen and (max-width: 768px) {
  .section-heading {
    width: 100%;
    margin-top: 30px;
  }

  .about-project-card {
    width: 100%;
    min-height: 250px;
  }

  .about-project-card img {
    border-radius: 8px;
    height: 150px;
  }

  .about-project-card p {
    font-size: 1rem;
    padding-top: 2px;
    padding-bottom: 6px;
  }
}
