.heading-holder {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.heading-holder h2 {
  font-family: "Jost-Medium";
  font-size: 1.5rem;
  color: var(--black-color);
}

.heading-holder span {
  font-family: "Jost-Light";
  font-size: 1rem;
  color: #494a67;
}

.heading-holder > div {
  margin-left: 30px;
}

.land-sku-item {
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.sold-out-card {
  background-color: #8b8b8b;
}

.apply-card {
  background-color: var(--white-color);
}

.btn-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-holder > div {
  display: flex;
  align-items: center;
}

.btn-holder img {
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin-right: 10px;
}

.btn-holder span {
  font-family: "Jost-Medium";
  font-size: 1.25rem;
  text-align: right;
  color: #9d9eb1;
}

.name-area-holder {
  display: flex;
  align-items: center;
}

.name-area-holder h3 {
  margin-right: 10px;
  font-family: "Jost-Bold";
  font-size: 25px;
}

.apply-now-name-text {
  color: var(--black-color);
}

.sold-out-name-text,
.sold-out-area-text,
.sold-out-amount,
.sold-out-description {
  color: var(--white-color);
}

.apply-now-area-text {
  color: #494a67;
}

.name-area-holder span {
  font-family: "Jost-Medium";
  font-size: 20px;
  line-height: 1.03;
}

.amount-holder {
  margin-top: 12px;
  margin-bottom: 12px;
}

.amount {
  font-family: "Jost-Medium";
  font-size: 24px;
  line-height: 0.77;
}

.apply-now-amount {
  color: var(--primary-theme-color);
}

.apply-now-description {
  color: #28282e;
}

.description {
  font-family: "Jost-Light";
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .heading-holder {
    margin-top: 20px;
  }

  .heading-holder h2 {
    font-size: 1rem;
  }

  .heading-holder span {
    font-size: 0.875rem;
  }

  .land-sku-item {
    padding: 10px 15px;
  }

  .name-area-holder h3 {
    font-size: 1rem;
  }

  .name-area-holder span {
    font-size: 1rem;
  }

  .amount-holder {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .amount {
    font-size: 1rem;
  }

  .description {
    font-size: 1rem;
  }

  .btn-holder img {
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }

  .btn-holder span {
    font-size: 0.875rem;
    text-align: center;
  }
}
