.sectionwrapper {
  padding: 30px;
  border-radius: 25px;
  background-color: var(--white-color);
}
.rmvbottomradius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rmvtopradius {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media only screen and (max-width: 600px) {
  .sectionwrapper {
    padding: 10px;
    border-radius: 10px;
  }
  .rmvbottomradius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .rmvtopradius {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
