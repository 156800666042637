.Section-heading-title {
  font-size: 1.4rem;
  font-family: "Jost-semiBold";
}

.font-jost-regular {
  font-family: "Jost-Regular";
}

.contain-padding {
  padding: 5px 15px 5px 15px;
}

.emergency-btn {
  background-color: #c94d4d;
  color: var(--white-color);
  width: 100% !important;
}

.emergency-btn:hover {
  background-color: var(--primary-red-color);
  color: var(--white-color);
  border-color: #c94d4d;
}

.siren-icon {
  width: 20px;
  height: 30px;
}

.pointer-cursor {
  cursor: pointer;
}

.ss {
  margin-left: 100px;
}

@media only screen and (min-width: 600px) {
  .emergency-btn {
    padding: 10px 15px;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .component-spacing-mobile {
    margin-top: 20px;
    padding: 0 10px 0 10px;
  }

  .emergency-btn-content {
    justify-content: center;
    text-align: center;
  }

  .section-heading-title {
    font-size: 1.2rem;
    color: var(--primary-theme-color);
    font-weight: bold;
  }

  .emergency-btn {
    font-size: 1rem;
  }
}