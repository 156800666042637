.price-trends-holder {
  margin-top: 50px;
}

.price-trend-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.price-trends-holder h2 {
  font-family: "Jost-Medium";
  font-size: 1.675rem;
  color: var(--quaternary-black-color);
  margin-bottom: 15px;
}

.price-trends-holder p {
  font-family: "Jost-Regular";
  font-size: 1.3rem;
  color: #292833;
  margin-top: 40px;
}

.price-rise-heading {
  font-family: "Jost-Regular";
  font-size: 1.625rem;
  color: #918fa3;
  margin-right: 10px;
}

.price-rise {
  font-family: "Jost-Bold";
  font-size: 1.625rem;
  color: #47b35f;
}

.upward-icon {
  height: 20px;
  margin-left: 5px;
  margin-right: 3px;
}

@media only screen and (max-width: 768px) {
  .price-trends-holder h2 {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  .price-trends-holder p {
    font-family: "Jost-Regular";
    font-size: 1rem;
    color: #292833;
    margin-top: 20px;
  }

  .price-rise-heading {
    font-size: 1rem;
    margin-right: 5px;
  }

  .price-rise {
    font-size: 1rem;
  }

  .upward-icon {
    height: 10px;
    margin-left: 5px;
  }
}
