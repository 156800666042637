.sku-detail-holder {
  margin: 20px;
  padding: 20px;
  background-image: linear-gradient(105deg, #faf4d1 6%, #f4e8a9 92%);
  border-radius: 0px 24px 30px 35px;
}

.name-area-holder {
  display: flex;
  align-items: center;
}

.name-area-holder h3 {
  font-family: "Jost-Bold";
  font-size: 1.25rem;
  color: var(--black-color);
  margin-right: 10px;
}

.name-area-holder span {
  font-family: "Jost-Medium";
  font-size: 1.25rem;
  line-height: 1.05;
  color: #494a67;
}

.amount-holder {
  margin-top: 12px;
  margin-bottom: 12px;
}

.amount {
  font-family: "Jost-Medium";
  font-size: 1.25rem;
  line-height: 0.82;
  color: var(--primary-theme-color);
}

.description {
  font-family: "Jost-Light";
  font-size: 20px;
  color: #28282e;
}

.content-text {
  font-family: "Jost-Medium";
  font-size: 1.25rem;
  text-align: center;
  color: #1c1c1c;
}

.divider {
  margin-right: 30px;
  margin-left: 30px;
}

.confirm-dialog-actions {
  padding: 10px 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirm-dialog-actions button {
  font-size: 1.125rem;
}
