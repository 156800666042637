.content-text {
  font-family: "Jost-Medium";
  font-size: 1.125rem;
  text-align: center;
  color: #1c1c1c;
  padding: 20px;
}

.divider {
  margin-right: 30px;
  margin-left: 30px;
}

.confirm-dialog-actions {
  padding: 10px 30px 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirm-dialog-actions button {
  font-size: 1.125rem;
}

.contained-dialog {
  padding: 40px 60px 0 60px;
}

.contained-dialog-margin {
  margin: 25px 30px 0 30px;
}