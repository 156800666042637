.user-info-holder {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.user-info-holder h2 {
  font-family: "Jost-Medium";
  font-size: 1.375rem;
  color: var(--black-color);
}

.user-info-holder>img {
  border: 4px solid var(--primary-theme-color);
  border-radius: 50%;
  object-fit: contain;
  height: 100px;
  width: 100px;
  margin-right: 25px;
}

.edit-profile-holder {
  display: flex;
  align-items: center;
}

.edit-icon {
  height: 16px;
  margin-right: 10px;
}

.edit-profile-link {
  color: var(--primary-theme-color);
  font-family: "Jost-Regular";
  font-size: 1rem;
  cursor: pointer;
}

.edit-profile-link:hover {
  text-decoration: underline;
}

.logout-btn-holder {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-btn {
  padding-right: 100px;
  padding-left: 100px;
}

.profile-pic-loader {
  position: absolute;
}

.profile-image-container {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-theme-color);
}

.profile-image-container div {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  border: 3px solid var(--white-color);
  display: flex;
  justify-content: center;
}

.profile-image-container h1 {
  font-family: "Jost-Regular";
  color: var(--white-color);
  text-align: center;
  font-size: 3.125rem;
}

.profile-image-container>img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  object-fit: cover;
}

@media only screen and (max-width: 700px) {
  .user-info-holder {
    margin-top: 20px;
  }

  .user-info-holder h2 {
    font-size: 1.18rem;
  }

  .edit-icon {
    height: 13px;
    margin-right: 8px;
  }

  .edit-profile-link {
    font-size: 0.8rem;
  }

  .logout-btn {
    padding-right: 50px;
    padding-left: 50px;
  }
}

/* This is profile menu item styles */

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  border-left: solid 1px var(--quaternary-light-gray-color);
  border-right: solid 1px var(--quaternary-light-gray-color);
  border-bottom: solid 1px var(--quaternary-light-gray-color);
  padding: 20px 25px;
}

/* .index-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  border: solid 1px var(--quaternary-light-gray-color);
  padding: 20px 25px;
  border-bottom: none;
} */

.menu-info-holder {
  display: flex;
  align-items: center;
}

.menu-info-holder>img {
  height: 30px;
  width: 40px;
  object-fit: contain;
  margin-right: 30px;
}

.menu-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu-info>h3 {
  font-family: "Jost-Medium";
  font-size: 1.125rem;
  color: var(--quaternary-black-color);
}

.menu-info>p {
  font-family: "Jost-Regular";
  font-size: 1rem;
  color: var(--senary-gray-color);
  margin-right: 20px;
}

@media only screen and (max-width: 768px) {
  .east-icon {
    font-size: 1rem;
  }

  .menu-info-holder>img {
    height: 20px;
    width: 30px;
    margin-right: 10px;
  }

  .menu-info>h3 {
    font-size: 0.93rem;
  }

  .menu-info>p {
    font-size: 0.82rem;
  }

  .index-menu,
  .menu {
    padding: 15px 20px 15px 5px;
  }
}