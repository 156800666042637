.heading-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.heading-holder h2 {
  font-family: "Jost-Regular";
  font-size: 24px;
  line-height: 1.6;
  color: #16161c;
}
