.update-card {
  min-height: 400px;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.06);
  background-color: var(--white-color);
  cursor: pointer;
  height: 460px;
  overflow: hidden;
}

.update-card > h2 {
  font-family: "Jost-Medium";
  font-size: 1.25rem;
  color: var(--black-color);
  margin-bottom: 8px;
}

.update-card span {
  margin-right: 5px;
  font-family: "Jost-Italic";
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.44;
  letter-spacing: normal;
}

.update-card > img {
  margin: 10px 0px;
  /* object-fit: contain; */
  width: 100%;
  height: 200px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: cover;
}

.project-name {
  color: #545566;
}

.location {
  color: #9d9eb1;
}

.description-text-ellipsis-4 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 13;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Jost-Regular";
  font-size: 1.1rem;
}

.description-text-ellipsis {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Jost-Regular";
  font-size: 1.1rem;
}

.line-ellipsis {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
