.white-color {
    color: var(--white-color);
}

.dialog-tilte {
    margin-bottom: 25px;
}

.dialog-image {
    width: 600px;
    height: 350px;
}

.dialog-content {
    padding: 0;
}

@media only screen and (max-width:700px) {
    .dialog-image {
        width: 100%;
        height: 250px;
    }

    .background-transparent {
        background-color: rgb(90, 88, 88);
        padding-top: 70px;
    }

}