.section-spacing {
    margin-top: 20px;
    margin-bottom: 10px;
}

.section-heading-name {
    font-size: 1.4rem;
    font-family: "Jost-semiBold";
}

.mui-card-description-details-section{
    margin: -15px -15px 0px -15px;
    text-align: center;
}

.accordion-summary-text{
    color : #3f416d;
    font-size: 1.25rem;
}

.typography-title{
    font-size: 1.3rem;
    font-family: "Jost-semiBold"
}

.typography-card-title{
    font-size: 1.3rem;
    font-family: "Jost-semiBold";
}

.typography-card-sub-title{
    font-size: 1.2rem;
    font-family: "Jost-Light";
}

.description-text{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Jost-Regular";
    font-size: 1.1rem;
}

.description-text-ellipsis-4{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Jost-Regular";
    font-size: 1.1rem;
}

.line-ellipsis{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-width:600px){

    .section-heading-name{
        font-size: 1.1rem;
    }

    .typography-title{
        font-size: 1.1rem;
    }

    .accordion-details-text{
        font-size: 0.9rem;
    }

    .typography-card-title{
        font-size: 1rem
    }

    .typography-card-sub-title{
        font-size: 0.9rem;
    }

    .description-text {
        text-overflow: ellipsis;
        font-size: 0.9rem;
    }
    .description-text-ellipsis-4{
        -webkit-line-clamp: 11;
        font-size: 0.9rem;
    }
    .accordion-summary-text{
        font-size: 1rem;
    }
}