.section-heading-holder,
.heading-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.section-heading-holder > div > h1 {
  font: 500 1.875rem "Jost-Medium";
}

.section-heading-holder > div > span {
  font: 500 1.5rem "Jost-Regular";
}

.last-few-plots-holder {
  margin-top: 50px;
}

.heading-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.heading-holder h2 {
  font-family: "Jost-Regular";
  font-size: 24px;
  line-height: 1.6;
  color: #16161c;
}

@media only screen and (max-width: 768px) {
  .section-heading-holder {
    margin-bottom: 10px;
  }

  .section-heading-holder > div > h1 {
    font-size: 1rem;
  }

  .section-heading-holder > div > span {
    font-size: 0.875rem;
  }
}
