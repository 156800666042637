.map-holder {
  height: 100%;
  width: 100%;
  position: relative;
}

.map {
  height: 500px;
  width: 100%;
}

.location-holder {
  background-color: #f6f6f6;
  padding-right: 15px;
  border-radius: 5px;
}

.around-project-content {
  max-height: 450px;
  overflow-y: scroll;
}

.location-holder h2 {
  font-family: "Jost-Medium";
  font-size: 22px;
  color: var(--black-color);
  /* margin-bottom: 10px; */
  /* position: fixed; */
  background-color: #f6f6f6;
}

.location-holder>div {
  overflow-y: auto;
  margin-top: 20px;
  background-color: #f6f6f6;
}

.recenter-map-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  background-color: var(--primary-theme-color);
  padding: 10px;
}

.location-holder>h2 {
  font-family: "Jost-Medium";
  font-size: 1.35rem;
  margin-left: 15px;
  margin-top: 10px
}

@media only screen and (max-width: 920px) {
  .location-holder>div {
    overflow-y: auto;
    overflow-x: auto;
    margin-top: 10px;
    display: flex;
  }

  .location-holder>h2 {
    font-size: 1.1225rem;
  }

  .location-holder {
    border-radius: 0;
  }
}