.tabs-content {
    color: black;
    padding-left: 30px
}

.typography-sub-heading-grey {
    color: #494a67;
    font-size: 1rem;
    font-family: "Jost-Medium";
}

.tab-icon-image {
    width: 60px;
    height: 45px;
    border-radius: 5px;
}

.graph-note-text {
    margin-bottom: 10px;
}

.paper-content {
    background-color: var(--white-color);
    padding: 15px 0px;
    box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.06);
    border-radius: 10px;
}

@media only screen and (max-width:600px) {
    .typography-sub-heading-grey {
        font-size: 0.8rem;
        font-family: "Jost-Light";
    }

    .tab-icon-image {
        width: 40px;
        height: 30px;
        border-radius: 3px;
    }
}