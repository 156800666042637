.read-notification-card {
  height: 120px;
  min-height: 120px;
  max-height: 120px;
  overflow: hidden;
  padding: 0 0 0 10px;
  box-shadow: none;
  background-color: var(--white-color);
  margin-bottom: 15px;
  cursor: pointer;
  border-bottom: 1px solid gainsboro;
}

.unread-notification-card {
  height: 120px;
  min-height: 120px;
  max-height: 120px;
  overflow: hidden;
  padding: 0 0 0 10px;
  border-radius: 12px;
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white-color);
  margin-bottom: 15px;
  cursor: pointer;
}

.heading-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.heading-holder h2 {
  font-family: "Jost-Regular";
  font-size: 24px;
  line-height: 1.6;
  color: #16161c;
}

.left-split {
  /* width: 80%; */
  float: left;
  height: 100%;
  padding: 10px;
}

.right-split {
  width: 20%;
  float: right;
  height: 100%;
}

.notifcation-title-holder {
  display: flex;
  align-items: center;
}

.notifcation-unread-title {
  font-family: "Jost-Medium";
  font-size: 24px;  
  line-height: 1.25;
  color: var(--primary-theme-color);
  margin: 0px 10px;
}

.notifcation-read-title {
  font-family: "Jost-Medium";
  font-size: 24px;  
  line-height: 1.25;
  color: var(--senary-gray-color);
  margin: 0px 10px 0px 0px;
}

.notification-received-time {
  font-family: "Jost-Medium";
  font-size: 22px;  
  line-height: 1.36;
  text-align: right;
  color: #ccab00;
}

.notification-read-time {
  font-family: "Jost-Medium";
  font-size: 22px;  
  line-height: 1.36;
  text-align: right;
  color: var(--senary-gray-color);
}

.new-notification-badge {
  background-color: #ffe76c;
  border-radius: 6px;
  padding: 0px 10px;
}

.new-notification-badge > span {
  font-family: "Jost-Regular";
}

.notification-content {
  padding-top: 12px;
  font-size: 1.125rem;
  font-family: "Jost-Regular";
  /* Display ellipses at the end of text after two lines */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-read-content {
  padding-top: 12px;
  font-size: 1.125rem;
  font-family: "Jost-Regular";
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6d6d7a;
}

.notification-icon {
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.no-data-section{
  display: table;
  height: 20vh;
  text-align: center;
}

.no-data-text{
  display: table-cell;
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  .read-notification-card {
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    overflow: hidden;
    padding: 0 0 0 5px;
    box-shadow: none;
    background-color: var(--white-color);
    margin-bottom: 10px;
    cursor: pointer;
  }

  .unread-notification-card {
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    overflow: hidden;
    padding: 0 0 0 5px;
    border-radius: 12px;
    box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white-color);
    margin-bottom: 10px;
    cursor: pointer;
  }

  .heading-holder h2 {
    font-size: 16px;
  }

  .notifcation-unread-title,
  .notifcation-read-title {
    font-size: 14px;
    line-height: 1.25;
  }

  .notification-received-time,
  .notification-read-time {
    font-size: 14px;    
  }

  .new-notification-badge {
    border-radius: 6px;
    padding: 0px 5px;
  }

  .new-notification-badge > span {
    font-size: 12px;
  }

  .notification-content,
  .notification-read-content {
    font-size: 1rem;
  }
}
