.about-us-section {
  padding: 10px;
}

.section-heading-name {
  font-size: 1.5rem;
  font-family: "Jost-Medium";
}

.sections-spacing {
  margin-top: 20px;
}

.sections-spacing-30 {
  margin-top: 30px;
}

.content-heading {
  font-size: 1.3rem;
}

.content-heading-2 {
  margin-bottom: 10px;
  font: normal 1.45rem "Jost-Medium";
}

.typography-title {
  font-family: "Jost-Medium";
}

.typography-sub-title {
  font-size: 1.15rem;
  font-family: "Jost-Medium";
  color: #494a67;
}

.typography-sub-title-gold {
  color: #928336;
}

.divider {
  margin: 10px -20px 10px -20px;
}

.divider-color {
  border-color: #e1d7a5;
}

.card-section,
.card-section-2 {
  margin-top: 20px;
  padding: 10px 20px 30px 20px;
  text-align: center;
  align-items: center;
  background-color: var(--white-color);
  white-space: pre-wrap;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.paper-sides-padding {
  padding: 10px 10% 30px 10%;
}

.carousel-container {
  padding: 10px 0px 10px 5px;
}

.carousel-item {
  padding: 20px;
  width: 100%;
  box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.carousel-item-image {
  width: 50px;
  height: 50px;
  text-align: start;
  justify-content: flex-start;
  align-items: flex-start;
}

.carousels-container {
  margin-top: 60px;
  text-align: center;
}

.product-category-img {
  height: 2%;
  width: 2%;
}

.project-category-details-card {
  margin-top: 20px;
  padding: 20px;
  background-color: var(--white-color);
  box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.display-name {
  font-size: 1.2rem;
  font-family: "Jost-Medium";
}

.line-clamp-3 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.display-name {
  font-size: 1.2rem;
  font-family: "Jost-semiBold";
}

.statsOverviewCard_Bg {
  background: linear-gradient(109deg, #faf4d1 6%, #f4e8a9 92%) !important;
  min-height: 240px;
  padding-bottom: 5px;
}

.margin-top-30px {
  margin-top: 30px;
}

.profit-icon {
  width: 50px;
  height: 30px;
  margin-right: 5px;
}

.btn-outlined {
  color: black;
  border-color: #9192a0;
  background-color: var(--white-color);
}

.btn-outlined:hover {
  color: black;
  border-color: black;
}

.description-ellipsis {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-ellipsis {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-center {
  justify-content: center;
  text-align: center;
  align-items: center;
}

.card-heading-name {
  font-size: 1.2rem;
  font-family: "Jost-Medium";
  text-overflow: ellipsis;
}

.left-arrow {
  cursor: pointer;
}

.corporate-content-3 {
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .about-us-section {
    padding: 0;
  }

  .carousel-container {
    min-width: 280px;
  }

  .divider {
    margin: 5px -10px;
  }

  .card-heading-name {
    font-size: 0.9rem;
  }

  .typography-sub-title {
    font-size: 1.05rem;
    color: black;
  }

  .section-heading-name {
    font-size: 1.3rem;
    color: var(--primary-theme-color);
    font-family: "Jost-Medium";
  }

  .content-heading,
  .content-heading-2 {
    font-size: 1.1rem;
    text-align: start;
  }

  .paper-sides-padding {
    padding: 10px;
  }

  .product-category-img {
    width: 8%;
    height: 8%;
  }

  .btn-text {
    font-size: 0.9rem;
  }

  .profit-icon {
    width: 20px;
    height: 20px;
  }

  .display-name {
    font-size: 1rem;
  }

  .carousel-item {
    padding: 5px 10px;
  }

  .carousel-item-image {
    height: 50px;
    width: 50px;
  }

  .description-ellipsis {
    -webkit-line-clamp: 8;
  }

  .project-category-details-card {
    min-height: 100px;
  }

  .card-section-2 {
    text-align: start;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .carousel-container {
    min-width: 280px;
  }

  .typography-sub-title,
  .card-heading-name {
    font-size: 0.9rem;
  }

  .section-heading-name {
    font-size: 1.3rem;
    color: var(--primary-theme-color);
    font-family: "Jost-Medium";
  }

  .paper-sides-padding {
    padding: 10px;
  }

}

@media only screen and (min-width: 900px) and (max-width: 1350px) {

  .paper-sides-padding {
    padding: 10px;
  }
}


@media only screen and (max-width: 1200px) {
  .carousel-container {
    min-width: 250px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1950px) {

  .statsOverviewCard_Bg {
    min-height: 300px;
  }
}